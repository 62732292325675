  <!-- 进度分析 -->
<template>
  <div>
    <!-- 第1行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 产值统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="outputChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="outputChartHandle"></project-select>
                </div>
                <!-- 产值统计，改为按项目维度的 -->
                <div style="width: 100%; float: left; overflow: hidden;color:red">
                  <div id="outputChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 第2行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 形象进度统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <project-select v-model="virtualScheduleChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="virtualScheduleChartHandle"></project-select>
                </div>
                <div style="width: 100%; float: left; overflow: hidden;color:red">
                  <div id="virtualScheduleChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
     <!-- 第3行 -->
     <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <!-- <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 形象进度统计</span>
          </div> -->
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  style="width: 100%"
                  max-height="300"
                  v-loading="tableDataLoading"
                  :header-cell-style="{
                    background: '#1cbbb4',
                    color: 'white',
                    textAlign: 'center',
                  }"
                >
                  <el-table-column prop="name" label="名称"></el-table-column>
                  <el-table-column prop="unitName" label="单位" width="100" align="center"></el-table-column>
                  <el-table-column prop="totalNum" label="工程量" width="180" align="center"></el-table-column>
                  <el-table-column prop="progress" label="施工进度" width="100" align="center">
                    <template slot-scope="scope">
                      <span>{{ formattedProgress(scope.row.progress)}} %</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {addDynamicRoute} from '@/router'
import { HashMap } from '@/utils'
export default {
  data () {
    return {
      // 产值统计
      outputChart: {
        prjId: '',
        instance: null
      },
      // 形象进度统计
      virtualScheduleChart: {
        prjId: '',
        instance: null
      },
      tableData: [],
      tableDataLoading: false,
      chartLoadingText:{// 图表loading效果
        text: '统计中，请稍候...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(3,3,8,0.1)',
        zlevel: 0,
      },
    }
  },
  created () {
    this.$nextTick(() => {
      this.echartsRegisterCustomShape()
      //  产值统计
      this.outputChart.instance = this.$echarts.init(document.getElementById('outputChart'))
      //  形象进度统计
      this.virtualScheduleChart.instance = this.$echarts.init(document.getElementById('virtualScheduleChart'))
    })
  },
  mounted() {
    this.$nextTick(() => {
      // 产值统计
      // this.outputChartHandle()
    })
  },
  methods: {
    openMenuRoute (name) {
      // 路由参数
      let routeParams = {
        routeName: name,
        path: 'ur2/reports/mps-project-report'
      }
      // 动态路由
      addDynamicRoute(routeParams,this.$router)
    },
    formattedProgress(progress) {
      return (progress * 100).toFixed(2)
    },
    // 产值统计
    outputChartHandle () {
      if (this.$hasPermission('home:dashboard:output')) {
        let chartData = {
          // 标段名/项目名
          names: [],
          // 合同额
          amounts: [],
          // 变更后金额
          changedAmounts: [],
          // 累计完成
          totalFinished: [],
          // 年度计划产值
          annualPlan: [],
          // 本年完成产值
          annualFinished: []
        }
        this.outputChart.instance.showLoading(this.chartLoadingText);
        this.$http.get(`/opm/home/outputChart?prjId=${this.outputChart.prjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          chartData = res.data
          this.setOutputChartOption(chartData)
          this.outputChart.instance.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    setOutputChartOption (chartData) {
      const option = {
        title: {
          // text: '产值统计'
        },
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} 万`;
            }
            return relVal;
          }
        },
        color:['rgba(11,42,106,.8)', '#3B80E2', '#22a2ad', '#2bb026', '#7c8019'],
        legend: {
          data: ['合同金额', '变更后合同额', '累计完成', '年度计划产值', '本年完成产值']
        },
        xAxis: {
          data: chartData.names
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 万'
          }
        },
        // todo 有数据后可以尝试把相关联的柱子叠加起来，对比效果更明显，比如把累计完成和合同额叠加起来（颜色需要调整）
        series: [
          {
            name: '合同金额',
            type: 'custom',
            renderItem: function(params, api) {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(7,29,97,.6)'
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(10,35,108,.7)'
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: 'rgba(11,42,106,.8)'
                  }
                }]
              }
            },
            data: chartData.amounts
          },
          {
            name: '变更后合同额',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.changedAmounts
          },
          {
            name: '累计完成',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#22a2ad'
              }, {
                offset: 1,
                color: '#49e5d3'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.totalFinished
          },
          {
            name: '年度计划产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#2bb026'
              }, {
                offset: 1,
                color: '#75e549'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.annualPlan
          },
          {
            name: '本年完成产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#7c8019'
              }, {
                offset: 1,
                color: '#d0e549'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  }]
              }
            },
            data: chartData.annualFinished
          },
        ],
      }
      if (this.outputChart) {
        this.outputChart.instance.clear()
        this.outputChart.instance.setOption(option)
      }
    },
    // 形象进度统计
    virtualScheduleChartHandle () {
      if (this.$hasPermission('home:dashboard:virtualSchedule')) {
        this.loadVirtualScheduleTableData()// 统计表
        this.virtualScheduleChart.instance.showLoading(this.chartLoadingText);
        this.$http.get(`/opm/home/virtualScheduleChart/${this.virtualScheduleChart.prjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 3000,
              onClose: () => {
                // do nothing
              }
            })
          }
          // key：name，value：{totalNum、finishedNum、percentage}
          let dataMap = new HashMap()
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              let dto = res.data[i]
              dataMap.put(dto.name, dto)
            }
          }
          this.seVirtualScheduleChartOption(dataMap) // 统计图
          this.virtualScheduleChart.instance.hideLoading()
        }).catch(() => {
          // do nothing
        })
      }
    },
    seVirtualScheduleChartOption (dataMap) {
      let names = dataMap.keys()
      let percentages = []
      for (let i = 0; i < names.length; i++) {
        percentages.push(dataMap.get(names[i]).percentage)
      }
      var option = {
        title: {
          // text: '形象进度统计'
        },
        grid: {// 控制上下左右边距
          x: 20,
          y: 60,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name
            let dto = dataMap.get(relVal)
            let _txt = (dto.finishedNum ? dto.finishedNum : 0) + '/' + dto.totalNum + '，' + dto.percentage + '%'
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${_txt}`;
            }
            return relVal;
          }
        },
        // legend: {
        //   data: ['形象进度']
        // },
        xAxis: {
          data: names
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          }
        },
        // dataZoom: [
        //   {
        //     type: 'slider',
        //     xAxisIndex: 0,
        //     show: false,
        //     startValue: 0,
        //     endValue: names.length > 15 ? 15 : names.length
        //   },
        //   {
        //     type: 'inside',
        //     show: true
        //   },
        // ],
        series: [
          {
            name: '形象进度',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: percentages
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '{c} %',
                fontSize: 16,
                // color: '#fff',
                offset: [4, -10]
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: percentages
          }
        ]
      }
      if (this.virtualScheduleChart) {
        this.virtualScheduleChart.instance.clear()
        this.virtualScheduleChart.instance.setOption(option)
      }
    },
    // 形象进度统计表格
    loadVirtualScheduleTableData() {
      this.tableDataLoading = true
      this.$nextTick(() => {
        // 查询形象工程量统计
        this.$http.get(`/opm/scheduleUnit/scheduleProgress/${this.virtualScheduleChart.prjId}`).then(({ data: res }) => {
          this.tableDataLoading = false
          if (res.code === 0) {
            this.tableData = res.data
          }
        }).catch(() => {
          this.tableDataLoading = false
        })
      })
    },
    // 根据当前年份动态生成最近五年的年份选项
    makeYearOptions() {
      let year = new Date().getFullYear()
      let arr = []
      for (let i = 0; i < 4; i++) {
        arr.push(year - i)
      }
      arr.sort()
      return arr;
    },
    // 预设十个渐变色，统计图可直接调用该方法获取颜色
    getLinears() {
      let linears = []

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#02bcff'
        }, {
          offset: 1, color: '#0081ff' // 海蓝
        }],
        global: false
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00'
        }, {
          offset: 1, color: '#00BB00' // 亮绿
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFB6C1'
        }, {
          offset: 1, color: '#FF69B4' // 桃粉
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCC99'
        }, {
          offset: 1, color: '#fbbd08' // 明黄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#e54d42' // 嫣红
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00CCFF'
        }, {
          offset: 1, color: '#00CCFF' // 亮蓝
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#99FFFF'
        }, {
          offset: 1, color: '#00FFFF' // 橄榄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFDDAA'
        }, {
          offset: 1, color: '#EE7700' // 棕褐
        }],
      })
      
      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#FF00FF' // 粉色
        }],
      })

      return linears
    },
    // echarts注册自定义图形
    echartsRegisterCustomShape() {
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x, shape.y]
          const c1 = [shape.x - 9, shape.y - 9]
          const c2 = [xAxisPoint[0] - 9, xAxisPoint[1] - 9]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1])
              .closePath()
        }
      })
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
          const c4 = [shape.x + 18, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          if (shape.y - shape.xAxisPoint[1] == 0) {
            return
          }
          const c1 = [shape.x, shape.y]
          const c2 = [shape.x + 18, shape.y - 9]
          const c3 = [shape.x + 9, shape.y - 18]
          const c4 = [shape.x - 9, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
      this.$echarts.graphic.registerShape('CubeRight', CubeRight)
      this.$echarts.graphic.registerShape('CubeTop', CubeTop)
    }
  }
}
</script>
<style scoped>
  ::v-deep .el-card {
    cursor: pointer;
  }

  ::v-deep .el-card .el-card__header {
    padding: 0;
    height: 30px;
    color: white;
  }

  ::v-deep .el-card__body {
    padding: 1px !important;
  }

  ::v-deep .header-default {
    background: #1cbbb4;
    padding: 5px;
  }

  ::v-deep .header-blue {
    background: #409EFF;
    padding: 5px;
  }

  ::v-deep .header-green {
    background: #67C23A;
    padding: 5px;
  }

  ::v-deep .header-brown {
    background: #E6A23C;
    padding: 5px;
  }

  ::v-deep .header-red {
    background: #F56C6C;
    padding: 5px;
  }

  ::v-deep .scrollbar-card {
    height: 200px;
  }

  ::v-deep .span-danger {
    font-weight: bold;
    font-size: 16px;
    color: red;
  }

  ::v-deep .span-warn {
    font-weight: bold;
    font-size: 16px;
    color: #E6A23C;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
  }

  li {
    list-style-type: none;
    padding-top: 5px;
  }

  .grid-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
  }

  .grid-con-icon-bg-blue {
    background: #2d8cf0;
  }

  .grid-con-icon-bg-green {
    background: #64d572;
  }

  .grid-con-icon-bg-orange {
    background: #f25e43;
  }

  .grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }

  .grid-cont-right {
    padding-left: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #999;
  }
  .myMapAngle {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -60px;
    border: 14px solid;
    left: 15px;
    bottom: -25px;
    opacity: 0.8;
    border-color: #333333 transparent transparent transparent;
  }
</style>