<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-flowable__procfess">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.processTitle" placeholder="流程主题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="dataForm.startTime" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="发起开始" end-placeholder="发起结束" @change="startTimeChange" style="width: 240px"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" style="width: 100%;" highlight-current-row
                :data="dataList" border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle">
        <el-table-column prop="processTitle" label="流程主题" header-align="center" align="left">
          <template slot-scope="scope">
            <a href="javascript:;" @click="showDetail(scope.row)">{{ scope.row.processTitle }}</a>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="startUser.realName" label="发起人" align="center" width="120"/>-->
        <el-table-column prop="startTime" label="发起时间" align="center" width="140"/>
        <el-table-column prop="endTime" label="结束时间" align="center" width="140"/>
        <el-table-column prop="currentElementName" label="当前节点" align="center" width="180"/>
        <el-table-column prop="businessStatus" label="当前状态" align="center" width="100">
          <template slot-scope="scope">
            <span :style="{ color: getBusinessStatusColor(scope.row.businessStatus) }">{{ getBusinessStatusTxt(scope.row.businessStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="耗时" align="center" width="120"/>
        <el-table-column label="操作" fixed="right" header-align="center" align="center" width="80">
          <template v-slot="{row}">
<!--            <el-button v-if="canRevoke(row.businessStatus)" type="text" size="small" @click="revoke(row)">撤销</el-button>-->
            <table-button role="delete" tooltip="撤销" @click="revoke(row)" v-if="canRevoke(row.businessStatus)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 撤销 -->
      <revoke-modal ref="revokeModal" :visible.sync="revokeVisible" @success="getDataList"/>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import RevokeModal from '@/views/modules/flowable/modal/RevokeModal'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/flowable/process/apply/page',
        getDataListIsPage: true
      },
      dataForm: {
        processTitle: '',
        startBeginTime: '',
        startEndTime: '',
        startTime: ''
      },
      revokeVisible: false
    }
  },
  components: {
    RevokeModal
  },
  methods: {
    showDetail(row) {
      if (!row.businessKey) {
        return this.$message.error(this.$t('task.detailError'))
      }
      this.getProcDefRouteSet(row, this.forwardDetail)
    },
    revoke(row) {
      this.revokeVisible = true
      this.$nextTick(() => {
        this.$refs.revokeModal.processInfo = {
          processInstanceId: row.processInstanceId
        }
      })
    },
    startTimeChange(startTimeList) {
      if (startTimeList) {
        this.dataForm.startBeginTime = startTimeList[0] + ' 00:00:00'
        this.dataForm.startEndTime = startTimeList[1] + ' 23:59:59'
      } else {
        this.dataForm.startBeginTime = ''
        this.dataForm.startEndTime = ''
      }
    }
  }
}
</script>
