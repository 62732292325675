<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__changeorder">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:changeorder:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:changeorder:delete')" @click="myDeleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        highlight-current-row border
        @sort-change="dataListSortChangeHandle"
        @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50" :selectable="myCheckSelectable"></el-table-column>
        <el-table-column prop="code" label="变更编号" min-width="140" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="变更项目名称" min-width="160" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="stakeNo" label="桩号" min-width="160" header-align="center" align="left" sortable="custom" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="changedAmountTotal" label="变更增减(元)" width="160" header-align="center" align="right" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ amountFormatter(scope.row.changedAmountTotal)}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="changeDate" label="变更时间" width="100" header-align="center" sortable="custom" align="center"></el-table-column> -->
        
        <el-table-column prop="publishStatus" label="发布状态" width="95" header-align="center" align="center" sortable="custom">
          <template v-slot="{row}">
            <el-tag v-if="row.publishStatus==1" type="success" size="small">已发布</el-tag>
            <el-tag v-else type="danger" size="small">未发布</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publishDate" label="发布时间" width="95" header-align="center" align="center" sortable="custom">
          <template v-slot="{row}">
            {{ row.publishDate }}
          </template>
        </el-table-column>
        <el-table-column label="发布" header-align="center" align="center" width="60">
          <template v-slot="{row}">
            <table-button status="primary" icon="my-icon-send" name="发布" v-if="$hasPermission('mps:changeorder:publish') && row.processStatus==2 && row.publishStatus != 1" type="text" size="small" @click="publishHandle(row)">发布</table-button>
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" header-align="center" align="center" width="120" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList" @beforeProcessSend="beforeProcessSendHandle"></process-status>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" header-align="center" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:changeorder:update') && myCheckSelectable(row)" @click="addOrUpdateHandle(row)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:changeorder:delete') && myCheckSelectable(row)" @click="myDeleteHandle(row)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:changeorder:update') || !myCheckSelectable(row))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './changeorder-add-or-update'
  import ViewPage from './changeorder-view'
  import processModule from '@/mixins/process-module'
  import XEUtils from 'xe-utils'

  export default {
    mixins: [mixinViewModule,processModule],
    data () {
      return {
        mixinViewModuleOptions: {
          getDataListURL: '/mps/changeOrder/page',
          getDataListIsPage: true,
          exportURL: '/mps/changeOrder/export',
          deleteURL: '/mps/changeOrder',
        },
        dataForm: {
          prjId: '',
          contractId: '',
          code: '',
          name: '',
        },
        order: 'desc',
        orderField: 'code',

        amountAccuracy: 0, // 金额小数位精度
      }
    },
    watch: {},
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      // 标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.amountAccuracy = data.contract.amountAccuracy
        this.getDataList()
      },

      // 新增或修改
      addOrUpdateHandle (row) {
        if (row) {
          if (row.publishStatus == 1) {
            return this.$message({
              message: '编号为[' + row.code + ']' + '已经发布，不能修改！',
              type: 'error',
              duration: 3000
            })
          }
        }
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = row ? row.id : null
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
          this.$refs.addOrUpdate.amountAccuracy = this.amountAccuracy
          this.$refs.addOrUpdate.init()
        })
      },

      // 查看
      viewHandle (id) {
        this.viewVisible = true
        this.$nextTick(() => {
          this.$refs.view.dataForm.id = id
          this.$refs.view.dataForm.contractId = this.dataForm.contractId
          this.$refs.view.amountAccuracy = this.amountAccuracy
          this.$refs.view.init()
        })
      },

      // 变更令发布
      publishHandle (row) {
        let msg = ''
        if (row.publishStatus == 1) {
          msg = '编号为[' + row.code + ']' + '已发布！'
        } else if (row.processStatus != 2) {
          msg = '编号为[' + row.code + ']' + '流程审核未通过，不能发布！'
        }
        if (msg) {
          return this.$message({
            message: msg,
            type: 'error',
            duration: 3000
          })
        } else {
          this.$confirm(`您确定要发布[${row.code}]吗？`,this.$t('prompt.title'),{
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            const loading = this.$loading({
              lock: true,
              text: '正在发布，请稍等...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.put('/mps/changeOrder/publish/' + row.id).then(({data: res}) => {
              loading.close()
              if (res.code !== 0) {
                return this.$message({
                  showClose: true,
                  message: res.msg,
                  type: 'error'
                })
              }
              this.$message({
                message: '发布成功！',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.getDataList()
                }
              })
            }).catch(() => {
              loading.close()
              return this.$message({
                showClose: true,
                message: '出错了！',
                type: 'error'
              })
            })
          })

        }
      },

      // 变更删除
      myDeleteHandle (row) {
        if (row) {
          if (row.publishStatus == 1) {
            return this.$message({
              message: '[' + row.code + ']' + '已发布，不能删除！',
              type: 'error',
              duration: 3000
            })
          } else {
            this.deleteHandle(row.id)
          }
        } else {
          this.deleteHandle()
        }
      },

      // 控制列表复选框是否可勾选
      myCheckSelectable (row) {
        if (row) {
          return row.publishStatus != 1 && this.checkSelectable(row)
        } else {
          return this.checkSelectable(row)
        }
      },

      // 金额格式化
      amountFormatter (amount) {
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(amount,{digits: this.amountAccuracy})
        } else {
          return amount
        }
      },

      // 支持在流程发起前进行一些业务处理（比如：校验业务数据是否完整）
      beforeProcessSendHandle(callback) {
        callback() // 注意：必须执行这个回调（该回调函数运行的是查询流程模板，及进行必要的相关流程校验）
      },
    }
  }
</script>