<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :leftWidth="300">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader include-sub @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
              v-if="treeShow"
              class="left-tree"
              v-loading="treeLoading"
              :data="treeData"
              :load="loadWbsNode"
              lazy
              node-key="id"
              :props="treeProps"
              ref="wbsTree"
              :expand-on-click-node="false"
              @node-click="treeNodeClick"
              :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__workstageinspection">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.wbsName" placeholder="分部分项" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="dataForm.item" placeholder="检查项" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-input v-model="dataForm.code" placeholder="编号" clearable></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="export" v-if="$hasPermission('mps:workstageinspection:export')" @click="exportHandle()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('mps:workstageinspection:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:workstageinspection:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
            <el-form-item>
              <el-alert title="请使用微信小程序或App进行工序报验" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
            <el-table-column type="selection" header-align="center" align="center" width="50" :selectable="checkSelectable"></el-table-column>
            <el-table-column prop="code" label="编号" width="160" header-align="center" align="center" sortable="custom">
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.code}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="wbsName" label="分部分项" show-overflow-tooltip>
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.pnames + "/" + row.wbsName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="workstageName" label="工序名称" width="140" sortable="custom">
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.workstageName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="item" label="检查项" show-overflow-tooltip width="200" sortable="custom">
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.item}}</el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="submitter" label="报验人/班组" width="150" show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column prop="position" label="报验部位" show-overflow-tooltip></el-table-column> -->
            <el-table-column prop="submitDate" label="报验日期" width="110" header-align="center" align="center" sortable="custom"></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="190">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('mps:workstageinspection:update') && checkSelectable(row)" @click="addOrUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('mps:workstageinspection:delete') && checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, ['工程实体照片','质检人员照片','实测实量照片','监理照片','其他报验资料'], !$hasPermission('mps:workstageinspection:update') || !checkSelectable(row), row.workstageName, null, true)"></table-button>
              </template>
            </el-table-column>
            <el-table-column prop="processStatus" label="流程状态" fixed="right" header-align="center" align="center" width="120" sortable="custom">
              <template slot-scope="scope">
                <process-status :biz="scope.row" :procTitle="scope.row.pnames + '/' + scope.row.wbsName + '【' + scope.row.workstageName + '】工序报验'" :key="scope.$index" @refreshDataList="getDataList"></process-status>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import AddOrUpdate from './workstageinspection-add-or-update'
import ViewPage from './workstageinspection-view'
export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/workstageInspection/page',
        getDataListIsPage: true,
        exportURL: '/mps/workstageInspection/export',
        deleteURL: '/mps/workstageInspection',
      },
      dataForm: {
        item: '',
        code: '',
        wbsName: '',
        wbsId: '',
        prjId: '',
        contractId: '',
        subcontractorId: '',
      },
      treeShow: false,
      treeLoading: false,
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        disabled: this.wbsNodeDisabled,
        isLeaf: 'isLeaf'
      },
      // 当前左侧树上选择的wbs是否叶节点（如果是叶节点，则点击新增时自动为用户选择好wbs）
      currentWbsIsLeaf: false,
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.dataForm.wbsId = null
      this.currentWbsIsLeaf = false
      this.getWbsTreeData()
      this.getDataList()
    },

    // 新增或修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        if (this.currentWbsIsLeaf) {
          this.$refs.addOrUpdate.dataForm.wbsId = this.dataForm.wbsId
        }
        this.$refs.addOrUpdate.init()
      })
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.contractId = this.dataForm.contractId
        this.$refs.view.dataForm.prjId = this.dataForm.prjId
        this.$refs.view.init()
      })
    },
    // 左侧 wbs树
    getWbsTreeData() {
      this.treeLoading = true
      this.treeShow = false
      this.$nextTick(() => {
        this.treeShow = true
        this.$http.get('/mps/workstageInspection/wbs/children',
            {
              params: {
                pid: "0",
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.treeData = res.data
          this.treeLoading = false
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },
    loadWbsNode(node, resolve) {
      if (node.level > 0) {
        let pid = node.data.id
        this.$http.get('/mps/workstageInspection/wbs/children',
            {
              params: {
                'pid': pid,
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data);
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }
    },
    // wbs节点点击事件
    treeNodeClick(data, node, ele) {
      this.dataForm.wbsId = data.id
      this.currentWbsIsLeaf = data.isLeaf
      this.getDataList()
    },
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}
</style>