<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetycheck">
      <el-form :inline="true">
        <el-form-item>
          <contract-cascader></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              v-model="dataForm.date"
              type="week"
              format="第 WW 周(yyyy/MM/dd)"
              placeholder="选择周">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" :max-height="tableHeight" highlight-current-row border style="width: 100%;">
        <el-table-column prop="name" label="标段名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="item" label="形象进度指标" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unit" label="单位" width="100" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="amount" label="总量" width="100" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="本周完成量" align="center" show-overflow-tooltip>
          <el-table-column prop="plan" label="计划量" width="120" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="complete" label="完成量" width="120" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="deviation" label="偏差值" width="120" align="center" show-overflow-tooltip>
            <template v-slot="{row}">
              <div :style="{'background-color': (row.color == 0 ? 'grey' : (row.color == 1 ? 'green' : 'red')), 'color': 'black'}">
                <span>{{row.deviation}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="rate" label="完成率" width="120" align="center" show-overflow-tooltip></el-table-column>
        </el-table-column>
        <el-table-column label="开累(截止至周月)" align="center" show-overflow-tooltip>
          <el-table-column prop="complete2" label="完成量" width="120" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="rate2" label="完成率" width="120" align="center" show-overflow-tooltip></el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from "@/mixins/view-module";

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
      },
      tableHeight: 400,
      dataForm: {},
      dataList: [
        {name: 'SG06-01', item: '承台', unit: '个', amount: '40', plan: '1.00', complete: '2.00', deviation: '1.00', rate: '200.00%', complete2: '3.00', rate2: '7.50%', color: 1},
        {name: 'SG06-01', item: '现浇墩柱', unit: '个', amount: '49', plan: '4.00', complete: '0.00', deviation: '-4.00', rate: '0.00%', complete2: '0.00', rate2: '0.00%', color: 2},
        {name: 'SG02', item: '现浇墩柱', unit: '个', amount: '106', plan: '4.00', complete: '5.00', deviation: '1.00', rate: '125.00%', complete2: '19.00', rate2: '17.92%', color: 1},
        {name: 'SG02', item: '桩基', unit: '棵', amount: '208', plan: '1.00', complete: '4.00', deviation: '3.00', rate: '400.00%', complete2: '53.00', rate2: '25.48%', color: 1},
        {name: 'SG02', item: '承台', unit: '个', amount: '100', plan: '3.00', complete: '2.00', deviation: '-1.00', rate: '66.67%', complete2: '22.00', rate2: '22.00%', color: 2},
        {name: 'SG08-02', item: '桩基', unit: '棵', amount: '200', plan: '4.00', complete: '7.00', deviation: '3.00', rate: '175.00%', complete2: '49.00', rate2: '24.50%', color: 1},
        {name: 'SG08-02', item: '现浇墩柱', unit: '个', amount: '83', plan: '2.00', complete: '2.00', deviation: '0.00', rate: '100.00%', complete2: '8.00', rate2: '9.64%', color: 0},
        {name: 'SG08-02', item: '承台', unit: '个', amount: '71', plan: '1.00', complete: '1.00', deviation: '0.00', rate: '100.00%', complete2: '9.00', rate2: '12.68%', color: 0},
        {name: 'SG07-03', item: '桩基', unit: '棵', amount: '62', plan: '12.00', complete: '18.00', deviation: '6.00', rate: '150.00%', complete2: '22.00', rate2: '35.48%', color: 1},
        {name: 'SG07-03', item: '现浇墩柱', unit: '个', amount: '52', plan: '1.00', complete: '2.00', deviation: '1.00', rate: '200.00%', complete2: '2.00', rate2: '3.85%', color: 1},
        {name: 'SG07-01', item: '承台', unit: '个', amount: '50', plan: '7.00', complete: '4.00', deviation: '-3.00', rate: '57.14%', complete2: '16.00', rate2: '32.00%', color: 2},
        {name: 'SG07-01', item: '桩基', unit: '棵', amount: '165', plan: '5.00', complete: '5.00', deviation: '0.00', rate: '100.00%', complete2: '108.00', rate2: '65.45%', color: 0},
        {name: 'SG07-01', item: '现浇墩柱', unit: '个', amount: '75', plan: '8.00', complete: '8.00', deviation: '0.00', rate: '100.00%', complete2: '14.00', rate2: '18.67%', color: 0},
        {name: 'SG05-02', item: '桩基', unit: '棵', amount: '147', plan: '10.00', complete: '3.00', deviation: '-7.00', rate: '30.00%', complete2: '109.00', rate2: '74.15%', color: 2},
        {name: 'SG05-02', item: '现浇墩柱', unit: '个', amount: '80', plan: '8.00', complete: '10.00', deviation: '2.00', rate: '125.00%', complete2: '24.00', rate2: '30.00%', color: 1},
        {name: 'SG05-02', item: '承台', unit: '个', amount: '44', plan: '3.00', complete: '6.00', deviation: '3.00', rate: '200.00%', complete2: '18.00', rate2: '40.91%', color: 1},
        {name: 'SG04-04', item: '承台', unit: '个', amount: '31', plan: '1.00', complete: '0.00', deviation: '-1.00', rate: '0.00%', complete2: '9.00', rate2: '29.03%', color: 2},
        {name: 'SG04-04', item: '现浇墩柱', unit: '个', amount: '43', plan: '8.00', complete: '2.00', deviation: '-6.00', rate: '25.00%', complete2: '8.00', rate2: '18.6%', color: 2},
        {name: 'SG05-01', item: '现浇墩柱', unit: '个', amount: '54', plan: '4.00', complete: '7.00', deviation: '3.00', rate: '175.00%', complete2: '8.00', rate2: '14.81%', color: 1},
        {name: 'SG05-01', item: '桩基', unit: '棵', amount: '134', plan: '10.00', complete: '7.00', deviation: '-3.00', rate: '70.00%', complete2: '96.00', rate2: '71.64%', color: 2},
      ],
    }
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.tableHeight=window.innerHeight-150
    })
  },
  methods: {
    
  }
}
</script>
<style scoped>
  
</style>