<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__contractcostitem">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="dataForm.code" placeholder="费用编号" clearable></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="费用名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.typeId" clearable placeholder="费用类型">
            <el-option :value="1" label="暂定金"></el-option>
            <el-option :value="2" label="工程款"></el-option>
            <el-option :value="3" label="预付款"></el-option>
            <el-option :value="4" label="保证金"></el-option>
            <el-option :value="5" label="奖罚款"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:contractcostitem:save')" @click="addOrUpdateHandle()" :disabled="!dataForm.contractId"></toolbar-button>
            <toolbar-button role="import" v-if="$hasPermission('mps:contractcostitem:import')" @click="importHandle"></toolbar-button>  
            <toolbar-button role="export" v-if="$hasPermission('mps:contractcostitem:export')" @click="exportHandle"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:contractcostitem:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList"
            highlight-current-row border
            @selection-change="dataListSelectionChangeHandle"
            @sort-change="dataListSortChangeHandle"
            :cell-style="{padding: '2px 0'}"
            style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="code" label="费用编号" width="150" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.code}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="费用名称" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="签约合同价（元）" width="150" header-align="center" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="typeId" label="费用类型" width="120" header-align="center" align="center" sortable="custom" show-overflow-tooltip :formatter="typeFormatter"></el-table-column>
        <el-table-column prop="statusId" label="报表类型" width="120" header-align="center" align="center" sortable="custom" show-overflow-tooltip :formatter="statusFormatter"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="edit" @click="addOrUpdateHandle(row.id)" v-if="$hasPermission('mps:contractcostitem:update')"></table-button>
            <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:contractcostitem:delete')"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:contractcostitem:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- excel文件导入弹窗 -->
      <excel-import v-if="excelImportVisible" ref="excelImport" @refreshDataList="getDataList" url="/mps/contractCostItem/import"></excel-import>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './contractcostitem-add-or-update'
import ViewPage from './contractcostitem-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/contractCostItem/page',
        getDataListIsPage: true,
        exportURL: '/mps/contractCostItem/export',
        deleteURL: '/mps/contractCostItem',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        code: '',
        name: '',
        typeId: '',
      },
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
    typeFormatter(row, column) {
      switch (row.typeId) {
        case 1:
          return "暂定金";
        case 2:
          return "工程款";
        case 3:
          return "预付款";
        case 4:
          return "保证金";
        case 5:
          return "奖罚款";
        default:
          return "";
      }
    },
    statusFormatter(row, column) {
      switch (row.statusId) {
        case 1:
          return "支付";
        case 2:
          return "扣回";
        case 3:
          return "其他";
        default:
          return "";
      }
    },
  }
}
</script>