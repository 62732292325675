<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__dept">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <toolbar-button role="add" v-if="$hasPermission('sys:dept:save')" @click="addOrUpdateHandle()"></toolbar-button>
        </el-form-item>
      </el-form>
      <el-table ref="deptTbl" v-loading="dataListLoading" 
        :data="dataList" row-key="id" highlight-current-row border style="width: 100%;" 
        :row-style="{height: '0px'}" 
        :cell-style="{padding: '2px 0'}" :default-expand-all="false" :expand-row-keys="expandRowKeys">
        <el-table-column prop="name" :label="$t('dept.name')" width="320"></el-table-column>
        <el-table-column prop="pnames" label="名称路径"></el-table-column>
        <el-table-column prop="typeId" label="机构类型" header-align="center" align="center" width="120" :formatter="deptTypeFormatter"></el-table-column>
        <!-- <el-table-column prop="parentName" :label="$t('dept.parentName')" header-align="center" align="center"></el-table-column> -->
        <!-- <el-table-column prop="sort" :label="$t('dept.sort')" header-align="center" align="center" width="100"></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="220">
          <template slot-scope="scope">
            <table-button role="addChild" v-if="$hasPermission('sys:dept:update')" @click="add(scope.row.id)"></table-button>
            <table-button role="update" v-if="$hasPermission('sys:dept:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:dept:delete')" @click="deleteHandle(scope.row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './dept-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/dept/list',
        deleteURL: '/sys/dept',
        deleteWithRelatedFiles: false,
        fetchDataSuccessCallback: this.fetchDataSuccessCallback
      },
      expandRowKeys: []
    }
  },
  components: {
    AddOrUpdate
  },
  
  methods: {
    fetchDataSuccessCallback() {
      // 设置默认展开第一级
      this.expandRowKeys = []
      for (let obj of this.dataList) {
        this.expandRowKeys.push(obj.id)
      }
    },
    deptTypeFormatter(row, column) {
      switch (row.typeId) {
        case 1:
          return "总公司";
        case 2:
          return "子公司";
        case 3:
          return "部门";
        case 4:
          return "项目公司";
        default:
          return "";
      }
    },
    add (parentId) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = parentId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>
