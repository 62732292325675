<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :leftWidth="230">
      <template v-slot:left>
        <div class="tree-container">
          <el-tree
              class="left-tree"
              :data="treeData"
              :props="treeProps"
              ref="wbsTree"
              default-expand-all
              :expand-on-click-node="true"
              :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__safetycheck">
          <el-form :inline="true">
            <el-form-item>
              <contract-cascader></contract-cascader>
            </el-form-item>
            <el-form-item>
              <span>状态：</span>
              <el-select v-model="dataForm.state" placeholder="请选择">
                <el-option :key="0" label="全部" :value="0"></el-option>
                <el-option :key="1" label="完工滞后" :value="1"></el-option>
                <el-option :key="2" label="正常" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button-group style="margin-top: -2px;">
                <el-button type="primary" icon="el-icon-search">查看模型</el-button>
                <el-button type="primary" icon="el-icon-thumb">一键生成计划量</el-button>
                <el-button type="primary" icon="el-icon-top-right">导出</el-button>
                <el-button type="primary" icon="el-icon-aim">进度模拟</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table :data="dataList" :max-height="tableHeight" highlight-current-row border style="width: 100%;">
            <el-table-column prop="state" label="状态" width="80" align="center" show-overflow-tooltip>
              <template v-slot="{row}">
                <span v-if="row.state == 1" style="color: green">正常</span>
                <span v-else style="color: red">完工滞后</span>
              </template>
            </el-table-column>
            <el-table-column label="查看历史" width="80" align="center" show-overflow-tooltip>
              <template>
                <i style="color: blue" class="el-icon-document-checked"></i>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="部位编码" width="250" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="部位名称" width="200" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="形象进度工程量" width="110" align="center" show-overflow-tooltip>
              <template>
                <i style="color: red" class="el-icon-document"></i>
              </template>
            </el-table-column>
            <el-table-column prop="user" label="责任人" width="80" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="计划" align="center" show-overflow-tooltip>
              <el-table-column prop="planStart" label="开始时间" width="100" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="planEnd" label="完成时间" width="100" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="planDuration" label="工期(天)" width="100" align="center" show-overflow-tooltip></el-table-column>
            </el-table-column>
            <el-table-column label="实际" align="center" show-overflow-tooltip>
              <el-table-column prop="planStart" label="开始时间" width="100" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="planEnd" label="完成时间" width="100" align="center" show-overflow-tooltip></el-table-column>
              <el-table-column prop="duration" label="工期(天)" width="100" align="center" show-overflow-tooltip></el-table-column>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              total="239"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from "@/mixins/view-module";

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
      },
      tableHeight: 400,
      dataForm: {
        state: 0
      },
      treeProps: {
        children: 'children',
        label: 'name',
      },
      treeData: [
        {
          name: '月进度预警',
          children: [
            {name: '2024年', children: [{name: '1月'},{name: '2月'},{name: '3月'},{name: '4月'},{name: '5月'},{name: '6月'},{name: '7月'},{name: '8月'},{name: '9月'},{name: '10月'},{name: '11月'},{name: '12月'},]},
            {name: '2025年', children: [{name: '1月'},{name: '2月'},{name: '3月'},{name: '4月'},{name: '5月'},{name: '6月'},{name: '7月'},{name: '8月'},{name: '9月'},{name: '10月'},{name: '11月'},{name: '12月'},]},
            {name: '2026年', children: [{name: '1月'},{name: '2月'},{name: '3月'},{name: '4月'},{name: '5月'},{name: '6月'},{name: '7月'},{name: '8月'},{name: '9月'},{name: '10月'},{name: '11月'},{name: '12月'},]},
          ]
        }
      ],
      dataList: [
        {state: 2, code: 'XHGZ-E-QL-XB-ZJ-UU-E2-UU-UU-01', name: '2号墩桩基-02', user: '', planStart: '2024-05-31', planEnd: '2024-06-01', planDuration: '1', duration: '3'},
        {state: 1, code: 'XHGZ-E-QL-XB-ZJ-UU-E3-UU-UU-01', name: '3号墩粧基-02', user: '', planStart: '2024-05-30', planEnd: '2024-05-31', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N QL XB-ZJ-UU-NO2-UU-FL-UU', name: '2号墩桩基-01', user: '', planStart: '2024-05-22', planEnd: '2024-05-23', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO3-UU-FL-UU', name: '3号墩桩基-01', user: '', planStart: '2024-05-23', planEnd: '2024-05-24', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-N03-UU-Z-01', name: '3号墩桩基-02', user: '', planStart: '2024-05-25', planEnd: '2024-05-26', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO4-UU-FL-UU', name: '4号墩粧基-01', user: '', planStart: '2024-05-24', planEnd: '2024-05-25', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO5 UU-FL-UU', name: '5号墩桩基-01', user: '', planStart: '2024-05-21', planEnd: '2024-05-22', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO5-UU-Z-01', name: '5号墩桩基-02', user: '', planStart: '2024-05-26', planEnd: '2024-05-27', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ N QL-XB-ZJ-UU-NO6-UU-FL-UU', name: '6号墩桩基-01', user: '', planStart: '2024-05-19', planEnd: '2024-05-20', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-N06-UU-Z-01', name: '6号墩粧基-02', user: '', planStart: '2024-05-27', planEnd: '2024-05-28', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO7-UU-FL-UU', name: '7号墩桩基-01', user: '', planStart: '2024-05-18', planEnd: '2024-05-19', planDuration: '1', duration: '1'},
        {state: 2, code: 'XHGZ-N-QL-XB-ZJ-UU-N07-UU-Z-01', name: '7号墩粧基-02', user: '', planStart: '2024-05-28', planEnd: '2024-05-29', planDuration: '1', duration: '2'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO8-UU-FL-UU', name: '8号墩粧基-01', user: '', planStart: '2024-05-16', planEnd: '2024-05-17', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-N08-UU-Z-01', name: '8号墩桩基-02', user: '', planStart: '2024-05-29', planEnd: '2024-05-30', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N-QL-XB-ZJ-UU-NO9-UU-FL-UU', name: '9号墩桩基-01', user: '', planStart: '2024-05-14', planEnd: '2024-05-15', planDuration: '1', duration: '1'},
        {state: 1, code: 'XHGZ-N QL-XB-ZJ-UU-NO9-UU-Z-01', name: '9号墩桩基-02', user: '', planStart: '2024-05-30', planEnd: '2024-05-31', planDuration: '1', duration: '1'},
      ],
    }
  },
  mounted() {
    this.$nextTick(()=>{
      //  表格高度设置
      this.tableHeight=window.innerHeight-200
    })
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 110px);
}
</style>