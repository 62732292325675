<template>
  <onlyoffice-editor :height="docHeight" :config="editorConfig" @outdated="reInitialEditor"></onlyoffice-editor>
</template>

<script>
import onlyofficeEditor from "@/views/pages/document-editor/onlyoffice-editor";
import {Decrypt} from "@/utils/encryption";

export default {
  props: {
    docHeight:{ // 文档高度
      type: [Number, String],
      default: '100vh'
    }
  },
  data() {
    return {
      editorConfig: {},
      configRequestParams: {
        ossId: '',
        actionLink: '',
        action: '',
        type: '',
      }
    }
  },
  components: {
    onlyofficeEditor
  },
  created() {
    if (this.$route.query && this.$route.query.param) {
      let params = JSON.parse(Decrypt(this.$route.query.param))
      this.configRequestParams = {...params}
      this.requestConfig()
    }
  },
  methods: {
    // 获取文档配置信息
    requestConfig() {
      this.$http.post(
          '/onlyoffice/onlyoffice/config',
          this.configRequestParams
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        //console.log(res.data)
        this.editorConfig = res.data
      }).finally(() => {
        // do nothing
      })
    },
    // 重新初始化文档编辑器
    reInitialEditor() {
      this.requestConfig()
    },
    // 手动设置编辑参数并初始化文档编辑器  目前用于流程页面嵌入使用
    manualInitialEditor(configParams){
      this.configRequestParams = {...configParams}
      this.requestConfig()
    }
  }
}
</script>
