<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__wbsprop">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <!-- <el-form-item>
          <el-input v-model="dataForm.code" placeholder="编号" clearable ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.typeId" clearable placeholder="请选择属性分类">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:wbsprop:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:wbsprop:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
        <el-form-item>
          <el-alert title="用于分部分项工程扩展其自定义属性" type="info" show-icon :closable="false" style="height: 28px"></el-alert>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="code" label="属性编号" width="180" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="name" label="属性名称" min-width="200" align="center" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="typeId" label="属性分类" width="120" align="center" show-overflow-tooltip sortable="custom">
          <template slot-scope="scope">
            {{getTypeName(scope.row.typeId)}}
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="150">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:wbsprop:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:wbsprop:delete')" @click="deleteHandle(row.id)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './wbsprop-add-or-update'
import ViewPage from './wbsprop-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/mps/wbsProp/page',
        getDataListIsPage: true,
        exportURL: '/mps/wbsProp/export',
        deleteURL: '/mps/wbsProp',
      },
      dataForm: {
        code: '',
        name: '',
        typeId: ''
      },
      // 构件属性分类
      typeOptions: [
        {
          value: 1,
          label: '施工信息'
        }, {
          value: 2,
          label: '试验信息'
        }, {
          value: 3,
          label: '原材信息'
        }
      ],
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 根据列表中属性分类ID,显示属性分类名称
    getTypeName(typeId){
      const val =this.typeOptions.find((element) => (element.value+'' === typeId + ''))
      if (val) {
        return val.label
      } else {
        return typeId||''
      }
    }
  }
}
</script>