<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__materialdeduct">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="标题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:materialdeduct:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:materialdeduct:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="periodName" label="期次" width="150" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="标题" min-width="150" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sortNo" label="排序" width="80" header-align="center" align="center" sortable="custom" show-overflow-tooltip></el-table-column> -->
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:materialdeduct:update') && checkSelectable(row)" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:materialdeduct:delete') && checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:materialdeduct:update') || !checkSelectable(row))"></table-button>
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" fixed="right" align="center" width="120" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './materialdeduct-add-or-update'
  import ViewPage from './materialdeduct-view'
  import processModule from '@/mixins/process-module'

  export default {
    mixins: [mixinViewModule,processModule],
    data () {
      return {
        mixinViewModuleOptions: {
          getDataListURL: '/mps/materialDeduct/page',
          getDataListIsPage: true,
          exportURL: '/mps/materialDeduct/export',
          deleteURL: '/mps/materialDeduct',
        },
        dataForm: {
          name: '',
          prjId: '',
          contractId: ''
        }
      }
    },
    watch: {},
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      // 标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.getDataList()
      },

      // 新增或修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
          this.$refs.addOrUpdate.init()
        })
      },
      // 查看
      viewHandle (id) {
        this.viewVisible = true
        this.$nextTick(() => {
          this.$refs.view.dataForm.id = id
          this.$refs.view.dataForm.contractId = this.dataForm.contractId
          this.$refs.view.dataForm.prjId = this.dataForm.prjId
          this.$refs.view.init()
        })
      }
    }
  }
</script>