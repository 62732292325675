<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList">
        <el-form-item>
          <el-input v-model="dataForm.name" :placeholder="nameTitle" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList"></toolbar-button>
            <toolbar-button role="print" @click="openPrint">打印</toolbar-button>
            <toolbar-button role="export" @click="openExport">导出</toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-row :gutter="10">
        <!-- <el-col :span="24">
          <h1 class="my-report-title">监理工作效率统计</h1>
        </el-col> -->
        <el-col :span="24" v-if="currentProject">
          <div style="margin-bottom: 5px;margin-left: 10px">
            <span style="margin-right: 10px">项目名称:</span>
            <el-tag closable @close="clearSelectProject">{{ currentProject.name }}</el-tag>
          </div>
        </el-col>
      </el-row>
      <vxe-table
          border
          resizable
          show-overflow
          ref="tableRef"
          :data="dataList"
          :loading="dataListLoading"
          :height="tableHeight"
          :row-config="{isCurrent: true, isHover: true}"
          :print-config="{}"
          :export-config="{}">
        <vxe-column type="seq" title="序号" width="60" align="center"></vxe-column>
        <vxe-column field="name" :title="nameTitle">
          <template #default="{ row }">
            <vxe-button v-if="!currentProject" type="text" status="primary" :content="row.name" @click="loadOneProjectData(row)"></vxe-button>
            <span v-else>{{ row.name }}</span>
          </template>
        </vxe-column>
        <vxe-column field="jlzl" width="100" title="监理指令" align="center" :formatter="numFormatter"></vxe-column>
        <vxe-column field="xsjl" width="100" title="巡视记录" align="center" :formatter="numFormatter"></vxe-column>
        <vxe-column field="pzjl" width="100" title="旁站记录" align="center" :formatter="numFormatter"></vxe-column>
        <vxe-column field="jlrz" width="100" title="监理日志" align="center" :formatter="numFormatter"></vxe-column>
      </vxe-table>
    </div>
  </el-card>
</template>
<!--监理工作效率-->
<script>
import mixinViewModule from '@/mixins/view-module'
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/opm/superviseReport/workEfficiency/list',
        getDataListIsPage: false,
      },
      dataForm: {
        prjId: '',
        name: '', // 标段名称
        prjName: '' // 项目名称
      },
      tableHeight: 400,
      // 打印顶部内容模板
      printTopHtml: `<h1 class="my-report-title">监理工作效率</h1>`,
      printConfig: {
        // 自定义打印的样式示例
        style: `.my-report-title {
                  text-align: center;
                }
                .my-list-row {
                  display: inline-block;
                  width: 100%;
                }
                .my-list-col {
                  float: left;
                  width: 50%;
                }
                `,
        // 输出数据的方式列表 current:当前页数据、selected:选中数据、all:全量（所有页数据）
        modes: ['current'],
        // 打印之前
        beforePrintMethod: ({content}) => {
          // 拦截打印之前，返回自定义的 html 内容
          if (this.currentProject) {
            this.printTopHtml = `
                                <h1 class="my-report-title">监理工作效率</h1>
                                <div class="my-list-row">
                                  <div class="my-list-col">项目名称：${this.currentProject.name}</div>
                                </div>
                                `
          } else {
            this.printTopHtml=`<h1 class="my-report-title">监理工作效率</h1>`
          }
          return this.printTopHtml + content;
        }
      },
      // vxeTable 导出配置
      exportConfig: {
        modes: ['current']
      },
      currentProject: null  // 选中的项目
    }
  },
  computed: {
    nameTitle: function () {
      return !this.currentProject ? '项目名称' : '标段名称'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 250
    })
  },
  watch: {
    'dataForm.name'() {
      this.dataForm.prjName = this.dataForm.name
    }
  },
  methods: {
    // vxeTable打印报表
    openPrint() {
      this.$refs.tableRef.openPrint(this.printConfig)
    },
    // vxeTable高级导出
    openExport() {
      this.$refs.tableRef.openExport(this.exportConfig)
    },
    // 数量格式化
    numFormatter({cellValue}) {
      return XEUtils.commafy(cellValue)
    },
    // 加载项目下每个标段的监理工作效率列表
    loadOneProjectData(row) {
      this.dataForm.prjId = row.id
      this.currentProject = row
      this.getDataList()
    },
    // 清除选中的项目
    clearSelectProject() {
      this.dataForm.prjId = ''
      this.currentProject = null
      this.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
.my-report-title {
  text-align: center;
}
</style>