<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini" label-width="1px">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
          <el-form-item>
            <el-button v-if="dataForm.contractId" type="text" icon="el-icon-plus" size="mini" @click="addMonth()">新增月份</el-button>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree class="left-tree"
                   v-loading="treeLoading"
                   node-key="id"
                   ref="myTree"
                   :data="treeData"
                   :props="defaultTreeProps"
                   :expand-on-click-node="false"
                   :highlight-current="true"
                   @node-click="treeNodeClick"
                   default-expand-all>
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseEnter(data,node)" @mouseleave="mouseLeave(data,node)">
              <span>{{ node.label }}</span>
              <span>
                <el-button v-if="$hasPermission('mps:materialmonthplan:delete')" v-show="data.show && !isProcessExist(data.data.processStatus)" type="text" size="mini" @click.stop="() => deleteMainHandle(data)">删除</el-button>
              </span>
            </span>
          </el-tree>
        </div>
        <main-add v-if="mainAddVisible" ref="mainAdd" @refreshDataList="refreshtTreeDataList" @close="closeDialogHandle"></main-add>
      </template>
      <template v-slot:right>
        <div class="mod-__materialmonthplan">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.materialName" placeholder="材料名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                <toolbar-button role="add" v-if="$hasPermission('mps:materialmonthplan:save') && !isProcessExist(processStatus)" @click="batchAddMxHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:materialmonthplan:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
            <el-form-item v-if="dataForm.contractId">
              <process-status v-if="dataForm.mainId"
                              btnSize="medium"
                              :biz="selectMonthPlan"
                              :procTitle="procTitle"
                              @refreshDataList="getDataList">
              </process-status>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" align="center" width="50" :selectable="myCheckSelectable"></el-table-column>
            <el-table-column label="年月" width="100" align="center" fixed="left">
              <template v-slot="{row}">{{ row.y + '年' + row.m + '月' }}</template>
            </el-table-column>
            <el-table-column prop="materialName" label="材料名称" min-width="150" fixed="left" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="spec" label="规格型号" width="200" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="num" label="数量" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="unit" label="单位" width="150" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" align="center" width="140">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('mps:materialmonthplan:update') && myCheckSelectable()" @click="mxUpdateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('mps:materialmonthplan:delete') && myCheckSelectable()" @click="deleteHandle(row.id)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeDialogHandle"></batch-add>
          <mx-update v-if="mxUpdateVisible" ref="mxUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></mx-update>
        </div>
      </template>
    </my-container>
  </el-card>
</template>
<!--材料月计划-->
<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
import MainAdd from './materialmonthplan-add'
import BatchAdd from "./materialmonthplan-mx-batch-add"
import MxUpdate from './materialmonthplan-mx-update'
import XEUtils from "xe-utils"

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/materialMonthPlanMx/page',
        getDataListIsPage: true,
        deleteURL: '/mps/materialMonthPlanMx',
      },
      dataForm: {
        contractId: '',
        y: '', // 年份
        m: '', // 月份
        mainId: '', // 月计划主表ID
        materialName: '' // 材料名称
      },
      selectMonthPlan: null, //选中的材料月计划
      treeLoading: false,
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'name'
      },
      mainAddVisible: false,
      yIdPrefix: 'my-', // 月计划树年份节点的Id前缀
      batchAddVisible: false,
      mxUpdateVisible: false
    }
  },
  components: {
    BatchAdd,
    MainAdd,
    MxUpdate
  },
  computed: {
    // 流程主题
    procTitle() {
      return this.selectMonthPlan ? this.selectMonthPlan.y + '年' + this.selectMonthPlan.m + '月材料月计划' : ''
    },
    // 流程状态
    processStatus() {
      return this.selectMonthPlan ? this.selectMonthPlan.processStatus : null
    }
  },
  methods: {
    // 合同标段切换事件
    contractChange(data) {
      this.dataForm.contractId = data.contractId
      this.refreshtTreeDataList()
    },
    // 刷新左侧月计划树列表
    refreshtTreeDataList() {
      this.dataForm.y = ''
      this.dataForm.m = ''
      this.dataForm.mainId = ''
      this.treeLoading = true
      // 清空数据
      this.selectMonthPlan = null
      this.treeData = []
      this.$refs.myTree.store.nodesMap = []
      this.dataList = []

      this.$http.get('/mps/materialMonthPlan/list',
          {
            params: {
              'contractId': this.dataForm.contractId
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let resultData = res.data || []
        if (resultData.length <= 0) {
          this.treeData = resultData
        } else {
          // 前端拼接树形结构 按照年月分组的树形数据结构
          let myTree = this.$refs.myTree
          for (let i = 0; i < resultData.length; i++) {
            let data = resultData[i]
            let y = data['y'] ? data['y'] + '' : ''
            let m = data['m'] ? data['m'] + '' : ''
            let yId = this.yIdPrefix + y
            let mId = data['id']
            let yearNode = myTree.getNode(yId)
            if (!yearNode) {
              myTree.append({
                id: yId,
                name: y + "年",
                y: y,
                m: '',
                data: null,
                children: []
              }, null)
            }
            let monthNode = myTree.getNode(mId)
            if (!monthNode) {
              myTree.append({
                id: mId,
                name: m + "月",
                y: y,
                m: m,
                data: data,
                children: []
              }, yId)
            }
          }
          // 默认选中第一个节点
          this.$nextTick(() => {
            document.querySelector('.el-tree-node__content').click()
          })
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 月计划树点击事件
    treeNodeClick(data) {
      this.dataForm.y = data.y
      this.dataForm.m = data.m
      this.selectMonthPlan = data.data
      // 过滤掉年份节点的Id
      this.dataForm.mainId = ''
      let id = data.id
      if (id && !XEUtils.startsWith(id, this.yIdPrefix)) {
        this.dataForm.mainId = id
      }
      this.getDataList()
    },

    mouseEnter(data, node) {
      // 叶子节点才显示
      if (node.isLeaf) {
        this.$set(data, 'show', true)
      }
    },
    mouseLeave(data, node) {
      if (node.isLeaf) {
        this.$set(data, 'show', false)
      }
    },

    // 删除月计划
    deleteMainHandle(data) {
      if (data) {
        this.$confirm(`您确定要删除${data.y}年${data.m}月的计划吗？`, this.$t('prompt.title'), {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true,
            text: '删除中...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          this.$http.delete(
              `/mps/materialMonthPlan`,
              {
                'data': [data.id]
              }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 1500
            })
            this.refreshtTreeDataList()
          }).catch(() => {
            // do nothing
          }).finally(() => {
            loading.close()
          })
        }).catch(() => {
          // do nothing
        })
      }
    },
    // 新增月计划
    addMonth() {
      this.mainAddVisible = true
      this.$nextTick(() => {
        this.$refs.mainAdd.dataForm.id = ''
        this.$refs.mainAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.mainAdd.init()
      })
    },
    // 批量新增月计划明细
    batchAddMxHandle() {
      if (!this.dataForm.m) {
        this.$message({
          message: '请首先选择左侧的具体月份',
          type: 'warning',
          duration: 2000
        })
      } else {
        this.batchAddVisible = true
        this.$nextTick(() => {
          this.$refs.batchAdd.dataForm.contractId = this.dataForm.contractId
          this.$refs.batchAdd.dataForm.mainId = this.dataForm.mainId
          this.$refs.batchAdd.dataForm.y = this.dataForm.y
          this.$refs.batchAdd.dataForm.m = this.dataForm.m
          this.$refs.batchAdd.init()
        })
      }
    },
    // 关闭弹窗
    closeDialogHandle() {
      this.mxUpdateVisible = false
      this.batchAddVisible = false
      this.mainAddVisible = false
    },
    // 材料月计划明细修改
    mxUpdateHandle(id) {
      this.mxUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.mxUpdate.dataForm.id = id
        this.$refs.mxUpdate.init()
      })
    },
    myCheckSelectable(){
      return this.dataForm.m && !this.isProcessExist(this.processStatus)
    }
  }
}
</script>
<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 180px);
}

::v-deep .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>