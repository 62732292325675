<template>
  <el-dialog :visible.sync="visible" title="报表模板分配" width="1120px" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <div class="transfer_dev">
      <el-transfer
        filterable
        :titles="['可选报表', '已选报表']"
        :filter-method="filterMethod"
        filter-placeholder="请输入报表名称"
        v-model="values"
        :data="data">
      </el-transfer>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 报表模板分配（基于luckysheet的报表体系）
import debounce from "lodash/debounce";
export default {
  data() {
    return {
      data: [],
      visible: false,
      refId: '',
      typeId: '',
      values: []
    }
  },
  methods: {
    init () {
      this.visible = true
      this.data = []
      this.values = []
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData() {
      let params= {}
      if (this.typeId) {
        params = {
          typeId: this.typeId
        }
      }
      this.$http.get('/mps/reportTemplate/briefs', {
        params
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let arr = res.data
        if (arr && arr.length > 0) {
          for (let dto of arr) {
            this.data.push({
              key: dto.id,
              //label: dto.typeLabel ? (dto.typeLabel + '  ' + dto.name) : dto.name
              label: dto.name
            })
          }
          // 查询已选择的报表
          this.$http.get('/mps/reportBinding/briefs', {
            params: {
              refId: this.refId,
            }
          }).then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            let arr = res.data
            if (arr && arr.length > 0) {
              for (let dto of arr) {
                this.values.push(dto.templateId)
              }
            }
          }).catch()
        }
      }).catch()
    },
    filterMethod(query, item) {
      return item.label.indexOf(query) > -1
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      const loading = this.$loading({
        text: '正在执行...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.put(`/mps/reportBinding/${this.refId}`, this.values).then(({ data: res }) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            loading.close()
            this.visible = false
            //this.$emit('refreshDataList', this.row)
          }
        })
      }).catch(() => {
        loading.close()
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }

}
</script>
<style scoped>
  .transfer_dev {
    display: flex; 
    justify-content: center; 
    align-items: center;
    width: 1080px;
  }
  ::v-deep .el-transfer-panel{
    width: 450px !important;
    height: 400px !important;
  }

  ::v-deep .el-transfer-panel__list {
    height: 290px !important;
  }

  ::v-deep .el-transfer__buttons {
    padding: 0 5px;
  }
</style>