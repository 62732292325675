<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-flowable__running">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.processTitle" placeholder="流程主题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.startUserName" placeholder="发起人" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="dataForm.startTime" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="startTimeChange" style="width: 240px"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <toolbar-button role="query" @click="getDataList()"></toolbar-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border style="width: 100%;">
        <el-table-column prop="processTitle" label="流程主题" min-width="200">
          <template slot-scope="scope">
            <a href="javascript:;" @click="taskDetail(scope.row)">{{ scope.row.processTitle }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="processDefinitionName" label="模板名称" width="290"></el-table-column>
        <el-table-column prop="startTime" label="发起时间" align="center" width="140"></el-table-column>
        <el-table-column prop="startUser.realName" label="发起人" align="center" width="120"></el-table-column>
        <el-table-column prop="businessStatus" label="当前状态" align="center" width="110">
          <template slot-scope="scope">
            <span :style="{ color: getBusinessStatusColor(scope.row.businessStatus) }">{{ getBusinessStatusTxt(scope.row.businessStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="耗时" align="center" width="120"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="80">
          <template slot-scope="scope">
            <el-button v-if="$hasPermission('flw:monitor:delete')" type="danger" size="mini" @click="myDeleteHandle(scope.row.processInstanceId)">{{ $t('delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import processModule from '@/mixins/process-module'
export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/flowable/process/monitor/page',
        getDataListIsPage: true,
        deleteURL: '/flowable/process',
        deleteWithRelatedFiles: false,
      },
      dataForm: {
        processTitle: '',
        startUserName: '',
        startBeginTime: '',
        startEndTime: '',
        startTime: ''
      }
    }
  },
  methods: {
    // 显示流程详情
    taskDetail(row) {
      if (!row.businessKey) {
        return this.$message.error(this.$t('task.detailError'))
      }
      this.getProcDefRouteSet(row, this.forwardDetail)
    },
    // 删除流程实例（自定义的deleteHandle方法）
    myDeleteHandle(id) {
      this.deleteHandle(id, {promptMessage: '您确定要删除该流程吗？'})
    },
    startTimeChange(startTimeList) {
      if (startTimeList) {
        this.dataForm.startBeginTime = startTimeList[0] + ' 00:00:00'
        this.dataForm.startEndTime = startTimeList[1] + ' 23:59:59'
      } else {
        this.dataForm.startBeginTime = ''
        this.dataForm.startEndTime = ''
      }
    }
  }
}
</script>
