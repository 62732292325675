<template>
  <div>
    <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <vxe-table id="table"
                 border
                 ref="tableRef"
                 size="mini"
                 show-overflow
                 :column-config="{resizable: true}"
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 :max-height="tableHeight"
                 :edit-config="{trigger: 'click', mode: 'row',showStatus:false}">
        <vxe-column type="seq" title="序号" width="80" align="center" fixed="left">
          <template v-slot:header>
            <vxe-button type="button" status="primary" content="添加" round @click="batchAdd()"></vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="materialName" title="材料名称" min-width="180" fixed="left"></vxe-column>
        <vxe-column field="spec" title="规格型号" width="200" :edit-render="{ name: '$input',autofocus: '.vxe-input--inner'}"></vxe-column>
        <vxe-column field="num" title="数量" align="right" :edit-render="{ name: '$input',props: {type: 'number'},autofocus: '.vxe-input--inner'}" width="150"></vxe-column>
        <vxe-column field="unit" title="单位" align="center" :edit-render="{ name: '$input',autofocus: '.vxe-input--inner'}" width="150"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <!--  物资明细多选  -->
    <material-more-select v-if="materialBatchSelectVisible" ref="materialBatchSelect" @submit="materialBatchSelectChangeHandle" @close="closeMaterialBatchSelectHandle"></material-more-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        y: '',
        m: '',
        mainId: ''
      },
      dataList: [],
      tableHeight: 400,
      materialBatchSelectVisible: false // 物资明细选择
    }
  },
  computed: {
    title: function () {
      if (this.dataForm.y && this.dataForm.m) {
        return this.dataForm.y + '年' + this.dataForm.m + '月计划'
      } else {
        return this.$t('add')
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  methods: {
    init() {
      this.visible = true
      // 为用户自动打开多选弹窗
      this.$nextTick(() => {
        this.batchAdd()
      })
    },
    // 数据提交
    dataFormSubmitHandle: debounce(function () {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 重新组装数据
      let dataListTemp = []
      this.dataList.forEach(function (obj) {
        // 规格型号，数量有一项填写，则认为是有效数据
        if (obj.spec || obj.num) {
          dataListTemp.push(obj)
        }
      })
      this.dataList = dataListTemp

      this.$http['post']('/mps/materialMonthPlanMx/batchSave', this.dataList).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
        // do nothing
      }).finally(() => {
        loading.close()
      })

    }, 1000, {'leading': true, 'trailing': false}),

    deleteOneHandle(row) {
      this.$refs.tableRef.remove(row)
    },

    // 打开多选弹窗
    batchAdd() {
      this.materialBatchSelectVisible = true
      this.$nextTick(() => {
        this.$refs.materialBatchSelect.dataForm.contractId = this.dataForm.contractId
        this.$refs.materialBatchSelect.init(true)
      })
    },

    // 关闭 物资明细选择弹窗
    closeMaterialBatchSelectHandle() {
      this.materialBatchSelectVisible = false;
    },

    // 批量选择物资明细的回调方法
    materialBatchSelectChangeHandle(result) {
      if (result && result.length > 0) {
        // 新增材料明细
        for (let i = 0; i < result.length; i++) {
          let obj = result[i];
          this.dataList.push({
            contractId: this.dataForm.contractId,
            mainId: this.dataForm.mainId,
            materialId: obj.materialId,
            materialName: obj.name,
            id: '',
            num: null,
            spec: obj.spec,
            unit: obj.unit1
          })
        }

        this.$message({
          message: '请继续填写规格型号和数量！',
          type: 'info',
          duration: 5000,
        })
      }
    },

  }
}
</script>