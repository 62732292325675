<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__completionplan">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true" @change="projectChange"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="计划名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.prjId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:completionplan:save')" @click="addOrUpdateHandle({id:''})"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:completionplan:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="code" label="计划编号" width="200" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="计划名称" min-width="200" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="contractName" label="合同名称" min-width="250" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="planAcceptanceDate" label="计划验收日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="organizer" label="验收组织者" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="participant" label="参加人员" width="200" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:completionplan:update')&& checkSelectable(row)" @click="addOrUpdateHandle(row)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:completionplan:delete')&& checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:completionplan:update')|| !checkSelectable(row))"></table-button>
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" fixed="right" align="center" width="120" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './completionplan-add-or-update'
import ViewPage from './completionplan-view'
import processModule from '@/mixins/process-module'

export default {
  mixins: [mixinViewModule, processModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/completionPlan/page',
        getDataListIsPage: true,
        deleteURL: '/mps/completionPlan',
      },
      dataForm: {
        prjId: '',
        name: '',
        prjName: '',
        prjCode: ''
      }
    }
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 项目切换事件
    projectChange(data) {
      this.dataForm.prjName = data.name
      this.dataForm.prjCode = data.code
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = row.id
        this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        this.$refs.addOrUpdate.dataForm.prjName = this.dataForm.prjName
        this.$refs.addOrUpdate.dataForm.prjCode = this.dataForm.prjCode
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>