<template>
    <div class="mod-__meas">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <!-- 若没有计量主表,或存在流转中的流程，则不显示按钮组 -->
        <el-form-item v-if="measId && canEdit">
          <el-button-group>
            <el-button icon="el-icon-plus" @click="batchAdd()" size="mini">新增</el-button>
            <!-- <toolbar-button role="add" @click="batchAdd()"></toolbar-button> -->
<!--            <toolbar-button role="delete" v-if="canEdit" @click="deleteHandle()"></toolbar-button>-->
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
          v-loading="dataListLoading"
          ref="table"
          :data="dataList"
          border
          row-key="id"
          highlight-current-row
          @selection-change="dataListSelectionChangeHandle"
          style="width: 100%;"
          :cell-style="{padding: '2px 0'}">
        <el-table-column prop="itemCode" label="费用编号" show-overflow-tooltip width="150"></el-table-column>
        <el-table-column prop="itemName" label="费用名称" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.itemName}}</el-button>
          </template>
        </el-table-column>
<!--        <el-table-column prop="itemStatus" label="支付/扣回" width="150" header-align="center" align="center" show-overflow-tooltip :formatter="statusFormatter"></el-table-column>-->
        <el-table-column prop="contractAmount" label="签约合同价（元）" align="right" show-overflow-tooltip>
          <template v-slot="{row}">
            <span>{{ moneyFormatter(row.contractAmount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sumByLastPeriod" label="上期末完成（元）" align="right" show-overflow-tooltip>
          <template v-slot="{row}">
            <span>{{ moneyFormatter(row.sumByLastPeriod) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="申请金额(元)" align="right">
          <template v-slot="{row}">
            <el-input v-if="canEdit" v-model="row.num" @change="numEdit(row)"></el-input>
            <span v-else>{{ row.num }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="measId && processStatus == 2" prop="checkedNum" label="审定金额(元)" align="right" show-overflow-tooltip>
          <template v-slot="{row}">
            <span >{{ row.checkedNum }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="160">
          <template v-if="processStatus == 2" v-slot="{row}">
            <vxe-button size="mini" @click="showLog(row)">审定日志</vxe-button>
            <vxe-button size="mini" @click="uploadDrawerHandle(row.id, [], true)">附件</vxe-button>
          </template>
          <template v-else v-slot="{row}">
            <table-button role="update" v-if="canEdit" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="canEdit" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !canEdit)"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
      <!-- 弹窗，批量新增 -->
      <batch-add v-if="batchAddVisible" ref="batchAdd" @refreshDataList="getDataList" @close="closeDialogHandle"></batch-add>
      <!-- 审批记录 -->
      <log v-if="logVisible" ref="log" @close="closeDialogHandle"></log>
    </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './meascost-add-or-update'
import ViewPage from './meascost-view'
import BatchAdd from './meascost-batch-add'
import Log from './measlog'
import {isNumber} from "@/utils/validate";
import processModule from '@/mixins/process-module'
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/measCostMx/page',
        getDataListIsPage: true,
        deleteURL: '/mps/measCostMx',
        fetchDataSuccessCallback: this.tableDoLayout
      },
      dataForm: {
        measId: '',
      },
      batchAddVisible: false,
      logVisible: false,
    }
  },
  watch: {
    measId: function(value) {
      this.dataForm.measId = value
      this.measChange()
    },
  },
  props: {
    measId: [String, Number],
    processStatus: Number,
    period: Object,
    contractId: [String, Number],
    canEdit: Boolean
  },
  components: {
    AddOrUpdate,
    ViewPage,
    BatchAdd,
    Log,
  },
  methods: {
    // 切换计量主表
    measChange() {
      if (this.measId) {
        this.getDataList()
      } else {
        this.dataList = []
      }
    },
    // 在el-table-column上使用v-if，以及在header插槽中使用v-if等有可能会改变单元格大小的情况，都可能会导致表格错乱
    // 所以调用此方法让table重新计算各单元格宽高以避免错乱问题
    tableDoLayout() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    // 批量新增
    batchAdd() {
      this.batchAddVisible = true
      this.$nextTick(() => {
        this.$refs.batchAdd.dataForm.contractId = this.contractId
        this.$refs.batchAdd.dataForm.measId = this.dataForm.measId
        this.$refs.batchAdd.title = `${this.period.name || ''}计量申请`
        this.$refs.batchAdd.init()
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.addOrUpdateVisible = false
      this.viewVisible = false
      this.batchAddVisible = false
      this.logVisible = false
    },
    
    // 修改申报数量
    numEdit(row) {
      if (!isNumber(row.num)) {
        this.$message({
          message: '申报金额必须为数字！',
          type: 'warning',
          duration: 3000,
          onClose: () => {
            // do nothing
          }
        })
        row.num = null
      } else {
        // row.num = XEUtils.toFixed(row.num, this.amountAccuracy)
        row.checkedNum = row.num
        this.$http['put']('/mps/measCostMx/', row).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).catch(() => {})
      }
    },
    // 查看审批记录
    showLog (row) {
      this.logVisible = true
      this.$nextTick(() => {
        this.$refs.log.dataForm.mxId = row.id
        this.$refs.log.init()
      })
    },
    statusFormatter(row, column) {
      switch (row.itemStatus) {
        case 1:
          return "支付";
        case 2:
          return "扣回";
        default:
          return "";
      }
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.title = `查看费用明细`
        this.$refs.view.init()
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.title = `修改费用明细`
        this.$refs.addOrUpdate.init()
      })
    },
    moneyFormatter(cellValue) {
      // return XEUtils.commafy(cellValue)
      return cellValue
    },
  }
}
</script>
