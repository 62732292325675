<template>
  <el-dialog top="5vh" width="70%" v-dialog-drag :visible.sync="visible" title="引用清单范本" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <div class="mod-__estGb">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
          </el-button-group>
        </el-form-item>

        <el-form-item label="【选择引用级别：】" style="margin-left: 40px;">
          <el-checkbox-group v-model="checkList" @change="bindCheckBox">
              <el-checkbox label="1">第一级</el-checkbox>
              <el-checkbox label="2">前二级</el-checkbox>
              <el-checkbox label="3">前三级</el-checkbox>
              <el-checkbox label="4">引用全部</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="范本名称" min-width="150" header-align="center" align="left" sortable="custom"></el-table-column>
      </el-table>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
// 引用清单范本
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/mps/boqTemplateMain/page',
        getDataListIsPage: true,
      },
      visible: false,
      dataForm: {
        name: '',
      },
      order: 'desc', // 排序，asc／desc
      orderField: 'sort_no',
      checkList: [],
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getDataList()
        }
      })
    },
    bindCheckBox(){
        if(this.checkList.length > 1){
          this.checkList.splice(0,1)
        }
    },
    submitHandle() {
      if (this.dataListSelections.length == 0) {
        return this.$message.error("请选择引用哪个范本！")
      } else if (this.dataListSelections.length > 1) {
        return this.$message.error("只允许选择一个范本！")
      }
      if (this.checkList.length > 0) {
        this.$emit('quoteSubmit', this.dataListSelections[0], this.checkList[0])
      } else {
        return this.$message.error("请选择引用级别！")
      }
    }
  }
}
</script>