<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="230">
      <template v-slot:left>
        <div class="tree-container">
          <el-tree v-loading="treeLoading" node-key="id" ref="myTree"
              :data="treeData"
              :props="defaultTreeProps"
              :expand-on-click-node="false"
              :highlight-current="true"
              icon-class="el-icon-document"
              @node-click="treeNodeClick" default-expand-all>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__othercontractpay">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true"></project-select>
            </el-form-item>
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="add" v-if="$hasPermission('mps:othercontractpay:save')" @click="addOrUpdateHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('mps:othercontractpay:delete')" @click="deleteHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" align="center" width="50" :selectable="checkSelectable"></el-table-column>
            <el-table-column prop="otherContractName" label="合同名称" min-width="300" align="left" show-overflow-tooltip sortable="custom">
              <template v-slot="{row}">
                <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.otherContractName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="applyDate" label="申请日期" width="110" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="paymentAmount" label="支付金额(元)" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="purpose" label="用途" width="220" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="260">
              <template v-slot="{row}">
                <table-button role="print" @click="printHandle(row.id)" v-if="$hasPermission('mps:othercontractpay:print')"></table-button>
                <table-button role="edit" @click="addOrUpdateHandle(row)" v-if="$hasPermission('mps:othercontractpay:update') && checkSelectable(row)"></table-button>
                <table-button role="delete" @click="deleteHandle(row.id)" v-if="$hasPermission('mps:othercontractpay:delete') && checkSelectable(row)"></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('mps:othercontractpay:update') || !checkSelectable(row))"></table-button>
              </template>
            </el-table-column>
            <el-table-column prop="processStatus" label="流程状态" fixed="right" header-align="center" align="center" width="120" sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 查看 -->
          <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
          <!-- 弹窗, 新增 / 修改 -->
          <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
        </div>
      </template>
    </my-container>

  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import {HashMap} from '@/utils'
import AddOrUpdate from './othercontractpay-add-or-update'
import ViewPage from './othercontractpay-view'
import processModule from '@/mixins/process-module'
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/otherContractPay/page',
        getDataListIsPage: true,
        exportURL: '/mps/otherContractPay/export',
        deleteURL: '/mps/otherContractPay',
      },
      dataForm: {
        prjId: null,
        otherContractId: null,
        otherContractTypeId: null
      },

      treeLoading: false,
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'name'
      },
      treeDataMap: new HashMap(),
      lastSelectedTreeId: ''
    }
  },
  created () {
    this.treeDataMap.put('-1', '全部合同')
    this.treeDataMap.put('1', '设计合同')
    this.treeDataMap.put('9', '其他合同')
  },
  watch: {},
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 项目切换事件
    prjChangeHandle (data) {
      this.dataForm.otherContractId = null
      this.dataForm.otherContractTypeId = null

      this.refreshTreeList()
      this.getDataList()
    },

    // 刷新左侧合同登记树列表
    refreshTreeList () {
      this.treeData = []
      this.$refs.myTree.store.nodesMap = []

      this.treeLoading = true
      this.$http.get('/mps/otherContract/list',
        {
          params: {
            prjId: this.dataForm.prjId,
            processStatus: 2  // 审批通过的合同
          }
        }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let dataList = res.data || []
        if (dataList.length > 0) {
          let myTree = this.$refs.myTree
          for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i]
            let typeId = data.typeId
            if (typeId) {
              let typeNode = myTree.getNode(typeId)
              if (!typeNode) {
                // 先创建合同登记的合同类型节点
                myTree.append({
                  id: typeId,
                  name: this.treeDataMap.get(typeId) || '',
                  typeId: typeId,
                  children: []
                }, null)
                typeNode = myTree.getNode(typeId)
              }
              myTree.append({
                id: data.id,
                name: data.name,
                otherContractId: data.id,
                icon: 'el-icon-document',
                children: []
              }, typeNode)
            } else {
              // 如果合同登记的合同类型（typeId）为空，则该合同直接加载到树的根节点上
              let rootNode = myTree.getNode('-1')
              if (!rootNode) {
                myTree.append({
                  id: '-1',
                  name: '全部合同',
                  children: []
                }, null)
              }

              myTree.append({
                id: data.id,
                name: data.name,
                otherContractId: data.id,
                icon: 'el-icon-document',
                children: []
              }, myTree.getNode('-1'))
            }
          }
        }
      }).catch(() => {
        // do thing
      }).finally(() => {
        this.treeLoading = false
      })
    },

    //  合同登记节点点击事件
    treeNodeClick (data, node, ele) {
      // 点击高亮节点则取消高亮
      if (this.lastSelectedTreeId == data.id) {
        this.lastSelectedTreeId = ''
        this.dataForm.otherContractTypeId = undefined
        this.dataForm.otherContractId = undefined
        this.$refs.myTree.setCurrentKey(null)
      } else {
        // 根据当前点击的节点，设置查询条件
        this.dataForm.otherContractTypeId = data.typeId
        this.dataForm.otherContractId = data.otherContractId
        this.lastSelectedTreeId = data.id
      }
      // 查询数据
      this.getDataList()
    },

    // 新增 / 修改
    addOrUpdateHandle (row) {
      if (row) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = row.id
          this.$refs.addOrUpdate.dataForm.prjId = row.prjId
          this.$refs.addOrUpdate.dataForm.otherContractId = row.otherContractId
          this.$refs.addOrUpdate.init()
        })
      } else {
        if (this.dataForm.otherContractId) {
          this.addOrUpdateVisible = true
          this.$nextTick(() => {
            this.$refs.addOrUpdate.dataForm.id = null
            this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
            this.$refs.addOrUpdate.dataForm.otherContractId = this.dataForm.otherContractId
            this.$refs.addOrUpdate.init()
          })
        } else {
          this.$message({
            message: '请先选择左侧的一个合同！',
            type: 'warning',
            duration: 2000
          })
        }
      }
    },
    // 打印支付请款单
    printHandle(id) {
      var params = qs.stringify({
        'access_token': Cookies.get('access_token'),
        'tenant_code_key': Cookies.get('tenantCode'),
        'id': id,
        'prjId': this.dataForm.prjId
      })
      window.open(`${window.SITE_CONFIG['apiURL']}/mps/otherContractPay/print?${params}`)
    },
  }
}
</script>
<style lang="scss" scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 120px);
  }

  ::v-deep .tree-container .el-tree-node {
    // white-space: normal;
    .el-tree-node__content {
      height: 100%;
    }
    
  }
</style>
