<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="分部分项" prop="wbsId">
            <wbs-select v-model="dataForm.wbsId" :contractId="dataForm.contractId" placeholder="选择分部分项" filterWorkstageInspection showPNames :leaf-only="true" @change="changeWbsHandle" :disabled="dataForm.id?true:false"></wbs-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报验工序" prop="workstageId">
            <el-select style="width: 100%" v-model="dataForm.workstageId" :disabled="dataForm.id ? true : false">
              <el-option v-for="opt in workstageList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="检查项" prop="item">
            <el-input v-model="dataForm.item" placeholder="请填写检查项"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="报验人/班组" prop="submitter">
            <el-input v-model="dataForm.submitter" placeholder="请填写报验人/班组"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="检查编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="请填写检查编号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="报验部位" prop="position">
            <el-input type="textarea" v-model="dataForm.position" placeholder="请填写报验部位" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="报验内容" prop="contents">
            <el-input type="textarea" v-model="dataForm.contents" placeholder="请填写报验内容" :autosize="autosize"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="报验日期" prop="submitDate">
            <el-date-picker style="width: 100%" v-model="dataForm.submitDate" value-format="yyyy-MM-dd" type="date" placeholder="请填写报验日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        wbsId: '',
        workstageId: '',
        code: '',
        item: '',
        submitter: '',
        position: '',
        contents: '',
        submitDate: '',
      },
      workstageList: [],
      autosize: {
        minRows: 3,
        maxRows: 10
      }
    }
  },
  computed: {
    dataRule() {
      let rules = {
        item: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        submitter: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        position: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        submitDate: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ]
      }
      if (!this.dataForm.id) {
        rules = {
          ...rules,
          wbsId: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ],
          workstageId: [
            {required: true, message: this.$t('validate.required'), trigger: 'blur'}
          ]
        }
      }
      return rules
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 默认时间
          this.dataForm.submitDate = new Date()
          // 自动生成编号（标段编号+yyyy-MM-dd+3位流水号）
          this.getNewCode()
          // 如果新增时自动填充了wbsId，则手动触发一次工序查询
          if (this.dataForm.wbsId) {
            this.loadWbsWorkstage(this.dataForm.wbsId)
          }
        }
      })
    },
    getInfo() {
      this.$http.get('/mps/workstageInspection/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.wbsId) {
          this.loadWbsWorkstage(this.dataForm.wbsId)
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 自动生成编号（标段编号+yyyy-MM-dd+3位流水号）
    getNewCode() {
      this.$http.get('/mps/workstageInspection/newCode/' + this.dataForm.contractId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm.code = res.data
      }).catch(() => {
        // do nathing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/workstageInspection/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
    // 切换wbs时查询对应的工序选项
    changeWbsHandle(wbs) {
      this.dataForm.workstageId = ''
      this.loadWbsWorkstage(wbs.id)
    },
    loadWbsWorkstage(wbsId) {
      this.workstageList.splice(0, this.workstageList.length)
      this.$http.get('/mps/wbsWorkstage/refList/' + wbsId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (this.dataForm.id) {
          this.workstageList = res.data
        } else {
          // 过滤掉已进行工序报验的（没有关联的工序报验，或者关联的工序报验流程状态为未通过（3））
          this.workstageList = res.data.filter((obj) => {
            return obj.inspectionProcessStatus == null || obj.inspectionProcessStatus == 3
          })
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>