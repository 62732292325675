<template>
  <div>
    <vxe-checkbox v-model="onlyType4" size="medium" style="margin-bottom: 4px" @change="filterData">
      <span style="color: blue">仅显示暂定清单</span>
    </vxe-checkbox>
    <vxe-table
        :data="filteredData"
        resizable
        :loading="dataListLoading"
        show-overflow="tooltip"
        highlight-hover-row
        :height="tableHeight - 20"
        :cell-class-name="cellClassName"
        :row-config="{isCurrent: true, isHover: true}"
        :scroll-y="{enabled: true}"
        :edit-config="{enabled: taskHandling, trigger: 'click', mode: 'row'}"
    >
      <vxe-column type="seq" width="40" fixed="left" align="center"></vxe-column>
      <vxe-column field="boqCode" title="清单编号" width="120" fixed="left"></vxe-column>
      <vxe-column field="boqName" title="清单名称" width="200">
        <template v-slot="{row}">
          <!-- precondition为1：安全生产费 -->
          <el-button v-if="row.precondition === 1" type="text" @click="viewSafetyCost()">{{ row.boqName }}</el-button>
          <span v-else>{{row.boqName}}</span>
          <!-- <span>{{row.boqName}}</span>
          <el-tooltip v-if="row.precondition === 1" placement="top-start" content="查看安全生产费详情" effect="light" :open-delay="0">
            <i class="el-icon-warning-outline" style="cursor:pointer;" @click="viewSafetyCost()"></i>
          </el-tooltip> -->
        </template>
      </vxe-column>
      <vxe-column field="unitName" title="单位" width="90" align="center"></vxe-column>
      <vxe-column field="num" title="本期申请" width="120" align="right"></vxe-column>
<!--      <vxe-column field="checkedNum" title="审定数量" width="120" align="right" header-class-name="red-header" :edit-render="{ name: 'MyInputNumber', props: {digitsKey: 'accuracy'}, events: {change: numOnChange, blur: numOnBlur} }"></vxe-column>-->
      <vxe-column :class-name="taskHandling ? 'cell-underline' : ''" field="checkedNum" title="审定数量" width="120" align="right" header-class-name="red-header"
                  :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: numOnChange}}"></vxe-column>
      <vxe-column :class-name="taskHandling ? 'cell-underline' : ''" field="opinion" title="审定意见" width="120" align="right" header-class-name="red-header"
                  :edit-render="{name: '$input', events: {change: opinionOnChange}}"></vxe-column>
      <vxe-column title="合同量" width="120" align="right">
        <template v-slot="{ row }">
          <el-tooltip placement="top" :content="calRestFull({row})" effect="light" :open-delay="500">
            <span>{{calRest({row})}}</span>
          </el-tooltip>
        </template>
      </vxe-column>
      <vxe-column title="累计完成" width="90" align="center" :formatter="calSum"></vxe-column>
      <vxe-column field="typeId" title="类型" width="90" align="center">
        <template v-slot="{ row }">
          <span v-if="row.typeId === 1" style="color: green">原始清单</span>
          <span v-else-if="row.typeId === 2 || row.typeId === 3" style="color: #F95738">变更清单</span>
          <span v-else-if="row.typeId === 4" style="color: #B22222">暂定清单</span>
          <span v-else style="color: #556B2F">未知类型</span>
        </template>
      </vxe-column>
      <vxe-column :visible="ticketType == 1" field="code" title="计量单号" width="250"></vxe-column>
      <vxe-column field="changeOrderCode" title="变更令编号" width="150"></vxe-column>
      <vxe-column title="工程或费用名称" width="250">
        <template v-slot="{ row }">
          {{row.subItemPnames + '/' + row.subItemName}}
        </template>
      </vxe-column>
      <vxe-column title="操作" width="90" align="center" fixed="right">
        <template #default="{row}">
          <el-button type="text" @click="showLog(row)">审定日志</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 审定日志 -->
    <log v-if="logVisible" ref="log" @close="closeDialogHandle"></log>
    <!-- 安全生产费明细 -->
    <safety-cost v-if="safetyCostVisible" ref="safetyCost" @close="closeDialogHandle"></safety-cost>
  </div>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import Log from './measlog'
import processModule from '@/mixins/process-module'
import XEUtils from "xe-utils";
import SafetyCost from './meas-mx-safetycost'

export default {
  mixins: [mixinViewModule, processModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/measMx/list',
        getDataListIsPage: false,
        exportURL: '/mps/meas/export',
        deleteURL: '/mps/measMx',
        fetchDataSuccessCallback: this.filterData,
      },
      dataForm: {
        mainId: '',
        subItemId: '',
        measId: '',
      },
      // 过滤后用于显示的数据列表，用于过滤出暂定清单
      filteredData: [],
      taskHandling: false,
      subItemName: '',
      processStatus: null,
      allowExceed: 0,
      logVisible: false,
      safetyCostVisible: false,
      taskId: '',
      // 计量单类型，如果为1，则是一对一（说明计量单号与计量明细一一对应，表格中需要显示计量单号）
      ticketType: '',
      // 仅显示暂定清单
      onlyType4: false,
    }
  },
  props: {
    tableHeight: Number
  },
  components: {
    Log,
    SafetyCost
  },
  methods: {
    getDataList() {
      if (!this.dataForm.measId) {
        this.dataList = []
        return
      }
      this.query()
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle () {
      this.logVisible = false
      this.safetyCostVisible = false
    },
    // 查看审批记录
    showLog (row) {
      this.logVisible = true
      this.$nextTick(() => {
        this.$refs.log.dataForm.mxId = row.id
        this.$refs.log.init()
      })
    },
    // 行样式
    cellClassName({row, column}) {
      if (column.field == 'checkedNum') {
        let rest = XEUtils.subtract(XEUtils.multiply(row.contractNum, row.percentage), row.sumByLastPeriod)
        if (row.checkedNum > rest) {
          return 'cell-over-meas'
        } else {
          return ''
        }
      } else if (column.title == '累计完成') {
        if (XEUtils.add(row.sumByLastPeriod, row.checkedNum) > row.contractNum) {
          return 'text-over-meas'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    // 数量格式化
    numFormatter ({cellValue,row}) {
      if (XEUtils.isNumber(row.accuracy)) {
        return XEUtils.commafy(cellValue,{digits: row.accuracy})
      } else {
        return cellValue
      }
    },
    // 计算上期末剩余数量
    // 原为剩余量，现改为合同量
    calRest({row}) {
      // return this.numFormatter({
      //   cellValue: XEUtils.subtract(row.contractNum, row.sumByLastPeriod),
      //   row: row
      // })
      return this.numFormatter({
        cellValue: row.contractNum,
        row: row
      })
    },
    // 原为剩余量，现改为合同量
    calRestFull({row}) {
      // return XEUtils.subtract(row.contractNum, row.sumByLastPeriod) + ''
      return row.contractNum + ''
    },
    calSum({row}) {
      let percent = XEUtils.toFixed(XEUtils.multiply(XEUtils.divide(XEUtils.add(row.sumByLastPeriod, row.checkedNum), row.contractNum), 100), 0)
      return percent + '%'
    },
    numOnChange({row}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        // 如果不允许超计量，则进行判断
        if (this.allowExceed != 1) {
          if (XEUtils.subtract(XEUtils.multiply(row.contractNum, row.percentage), row.sumByLastPeriod) < event.value) {
            this.$alert(`清单 ${row.boqCode} ${row.boqName} 剩余可计量数量是${XEUtils.subtract(XEUtils.multiply(row.contractNum, row.percentage), row.sumByLastPeriod)}，您申请的数量已超出限制！`, '操作出错', {
              confirmButtonText: '关闭',
            })
            return
          }
        }
        this.$http['put'](
            '/mps/measLog/measProcessUpdate',
            {
              mxId: row.id,
              taskId: this.taskId,
              quantity: event.value,
              opinion: row.opinion
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$bus.$emit('measAmountChange')
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).catch(() => {})
      }
    },
    opinionOnChange({row}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.$http['put'](
            '/mps/measLog/measProcessUpdate',
            {
              mxId: row.id,
              taskId: this.taskId,
              quantity: row.checkedNum,
              opinion: event.value
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              // do nothing
            }
          })
        }).catch(() => {})
      }
    },
    filterData() {
      if (this.onlyType4) {
        this.filteredData = this.dataList.filter((row) => {
          if (row.typeId === 4) {
            return true
          } else {
            return false
          }
        })
      } else {
        this.filteredData = this.dataList
      }
    },
    // 查看安全生产费详情
    viewSafetyCost() {
      this.safetyCostVisible = true
      this.$nextTick(() => {
        this.$refs.safetyCost.measId = this.dataForm.measId
        this.$refs.safetyCost.init()
      })
    },
  }
}
</script>
<style scoped>
  ::v-deep .cell-over-meas {
    background: rgb(247, 149, 149) !important;
  }
  ::v-deep .text-over-meas {
    color: red
  }
  ::v-deep .red-header {
    color: red
  }
</style>