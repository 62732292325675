<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__log-operation">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.creatorName" placeholder="操作用户" clearable style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.operation" placeholder="操作内容" clearable style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.ip" placeholder="IP地址" clearable style="width: 140px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.requestUri" placeholder="请求路径" clearable style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.status" :placeholder="$t('logOperation.status')" clearable style="width: 100px">
            <el-option :label="$t('logOperation.status0')" :value="0"></el-option>
            <el-option :label="$t('logOperation.status1')" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="export" @click="exportHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" 
          highlight-current-row border
          :max-height="maxTableHeight"
          @sort-change="dataListSortChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column prop="creatorName" fixed label="操作用户" align="center" width="160" sortable="custom"></el-table-column>
        <el-table-column prop="operation" fixed label="操作内容" width="280" sortable="custom"></el-table-column>
        <el-table-column prop="createDate" fixed label="操作时间" sortable="custom" align="center" width="140"></el-table-column>
        <el-table-column prop="ip" label="IP地址" align="center" width="120" sortable="custom"></el-table-column>
        <el-table-column prop="module" :label="$t('logOperation.module')" align="center" width="100" sortable="custom"></el-table-column>
        <el-table-column prop="requestUri" label="请求路径" width="280" sortable="custom"></el-table-column>
        <el-table-column prop="requestTime" :label="$t('logOperation.requestTime')" sortable="custom" align="center" width="100">
          <template slot-scope="scope">
            {{ `${scope.row.requestTime} ms` }}
          </template>
        </el-table-column>
        <el-table-column prop="requestMethod" :label="$t('logOperation.requestMethod')" align="center" width="100" sortable="custom"></el-table-column>
        <el-table-column prop="status" :label="$t('logOperation.status')" sortable="custom" align="center" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" size="small" type="danger">{{ $t('logOperation.status0') }}</el-tag>
            <el-tag v-else size="small" type="success">{{ $t('logOperation.status1') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="requestParams" :label="$t('logOperation.requestParams')" width="500" show-overflow-tooltip></el-table-column>
        <el-table-column prop="userAgent" label="用户设备" width="400" show-overflow-tooltip sortable="custom"></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/log/operation/page',
        getDataListIsPage: true,
        exportURL: '/sys/log/operation/export'
      },
      dataForm: {
        module: '',
        status: '',
        operation: '',
        ip: '',
        requestUri: '',
        creatorName: ''
      },
      maxTableHeight: 800,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.maxTableHeight = window.innerHeight - 200
    })
  },
}
</script>
