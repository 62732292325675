<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__changerequest">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="申请编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="变更项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('mps:changerequest:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('mps:changerequest:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        highlight-current-row border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50" :selectable="checkSelectable"></el-table-column>
        <el-table-column prop="code" label="申请编号" width="200" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="变更项目名称" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="content" label="申请内容" header-align="center" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="requestAmountTotal" label="变更增减(元)" width="150" header-align="center" align="right" sortable="custom">
          <template slot-scope="scope">
            <span>{{ amountFormatter(scope.row.requestAmountTotal)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="requestDate" label="申请日期" width="120" header-align="center" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="tentativeMeas" label="申请类型" width="160" sortable="custom">
          <template v-slot="{row}">
            <el-tag v-if="row.tentativeMeas == 1" type="danger">暂定计量申请</el-tag>
            <el-tag v-if="row.tentativeMeas == 0" type="success">变更申请</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publishDate" label="发布时间" width="95" header-align="center" align="center" sortable="custom">
          <template v-slot="{row}">
            {{ row.publishDate }}
          </template>
        </el-table-column>
        <el-table-column prop="processStatus" label="流程状态" fixed="right" header-align="center" align="center" width="120" sortable="custom">
          <template slot-scope="scope">
            <!-- 注意：bizRoute一定写相对路径  -->
            <process-status :biz="scope.row" :procTitle="scope.row.name" :key="scope.$index" @refreshDataList="getDataList"></process-status>
          </template>
        </el-table-column>

        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('mps:changerequest:update') && checkSelectable(row)" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('mps:changerequest:delete') && checkSelectable(row)" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, ['变更前模型','变更后模型','其他'], !$hasPermission('mps:changerequest:update') || !checkSelectable(row))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
  import mixinViewModule from '@/mixins/view-module'
  import AddOrUpdate from './changerequest-add-or-update'
  import ViewPage from './changerequest-view'
  import processModule from '@/mixins/process-module'
  import XEUtils from 'xe-utils'

  export default {
    mixins: [mixinViewModule,processModule],
    data () {
      return {
        mixinViewModuleOptions: {
          getDataListURL: '/mps/changeRequest/page',
          getDataListIsPage: true,
          exportURL: '/mps/changeRequest/export',
          deleteURL: '/mps/changeRequest',
        },
        dataForm: {
          prjId: '',
          contractId: '',
          code: '',
          name: '',
        },
        order: 'desc',
        orderField: 'code',

        amountAccuracy: 0, // 金额小数位精度
      }
    },
    components: {
      AddOrUpdate,
      ViewPage
    },
    methods: {
      // 标段切换事件
      contractChange (data) {
        this.dataForm.prjId = data.prjId
        this.dataForm.contractId = data.contractId
        this.amountAccuracy = data.contract.amountAccuracy
        this.getDataList()
      },

      // 新增或修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.dataForm.id = id
          this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
          this.$refs.addOrUpdate.amountAccuracy = this.amountAccuracy
          this.$refs.addOrUpdate.init()
        })
      },
      // 查看
      viewHandle (id) {
        this.viewVisible = true
        this.$nextTick(() => {
          this.$refs.view.dataForm.id = id
          this.$refs.view.dataForm.contractId = this.dataForm.contractId
          this.$refs.view.amountAccuracy = this.amountAccuracy
          this.$refs.view.init()
        })
      },

      // 金额格式化
      amountFormatter (amount) {
        if (XEUtils.isNumber(this.amountAccuracy)) {
          return XEUtils.commafy(amount,{digits: this.amountAccuracy})
        } else {
          return amount
        }
      }
    }
  }
</script>