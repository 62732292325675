<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__archivelation">
      <el-row>
        <el-col :span="12">
          <el-tabs value="tab1">
            <el-tab-pane label="预组卷管理" name="tab1">
              <el-tree key="pre" ref="treePre" v-loading="preDataLoading"
                       :data="preCatalogData" :props="treeProps" :load="loadPreNode"
                       class="tree-container" node-key="id" :allow-drag="preAllowDrag"
                       :allow-drop="preAllowDrop" @node-drag-start="handleDragstart"
                       @node-drag-end="preNodeDragEnd" lazy draggable>
                <template v-slot="{ data }">
                  <span v-if="data.archiveType == archiveType.catalog" style="color: #0e9d6a">{{ data.name }}</span>
                  <span v-else>{{ data.name }}</span>
                </template>
              </el-tree>
            </el-tab-pane>
            <el-tab-pane name="msg" disabled>
              <span slot="label"><i class="el-icon-info" style="margin-right: 6px"></i>拖动预组卷目录（绿色）到组卷管理的案卷目录（绿色）上。</span>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="12">
          <el-tabs value="tab2">
            <el-tab-pane label="组卷管理" name="tab2">
              <el-tree key="post" ref="treePost" :default-expanded-keys="postExpandedKeys"
                       v-loading="postDataLoading" :data="postCatalogData"
                       :props="treeProps" :load="loadPostNode" class="tree-container"
                       node-key="id" :allow-drag="postAllowDrag" :allow-drop="postAllowDrop"
                       @node-drop="postNodeDrop" lazy draggable>
                <template v-slot="{ data }">
                  <span v-if="data.archiveType == archiveType.catalog" style="color: #0e9d6a">{{ data.name }}</span>
                  <span v-else>{{ data.name }}</span>
                </template>
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>
// 预组卷复制到组卷
import XEUtils from 'xe-utils'
export default {
  data() {
    return {
      preCatalogData: [],
      postCatalogData: [],
      preDataLoading: false,
      postDataLoading: false,
      treeProps: {
        label: 'name',
        children: 'children',
        isLeaf: this.hasChildren
      },
      archiveType: { // 节点类型
        outline: 1, // 分类大纲
        catalog: 2, // 案卷目录
        dir: 3 // 卷内目录
      },
      postExpandedKeys: [] // 组卷树展开的节点
    }
  },
  created() {
    this.loadOutlineData()
  },
  methods: {
    loadOutlineData() {
      // 预组卷目录
      this.preDataLoading = true
      this.$http.get('/mps/archiveOutline/preTree').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.preCatalogData = XEUtils.toArrayTree(res.data, {key: 'id', parentKey: 'pid', children: 'children'})
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.preDataLoading = false
      })
      // 案卷目录及卷内目录
      this.postDataLoading = true
      this.$http.get('/mps/archiveOutline/postTree').then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.postCatalogData = XEUtils.toArrayTree(res.data, {key: 'id', parentKey: 'pid', children: 'children'})
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.postDataLoading = false
      })
    },
    
    // 加载预组卷目录
    loadPreNode(node, resolve) {
      if (node.level != 0) { // 根节点点击加载
        let data = node.data
        if (data.archiveType == this.archiveType.outline) {
          if (data.outlineLeaf) {
            this.loadCatalogChildren(data.id, resolve)
          } else {
            resolve(data.children)
          }
        } else if (data.archiveType == this.archiveType.catalog) {
          this.loadCatalogChildren(data.id, resolve)
        }
      }
    },

    // 加载预组卷目录
    loadCatalogChildren(pid, resolve) {
      this.$http.get(`/mps/archivePreCatalog/children/${pid}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          res.data.forEach(item => {
            item.archiveType = this.archiveType.catalog
          })
        }
        resolve(res.data)
      })
    },

    // 加载组卷管理的案卷目录、卷内目录
    loadPostNode(node, resolve) {
      if (node.level != 0) {
        let data = node.data
        if (data.archiveType == this.archiveType.outline) {
          if (data.outlineLeaf) {
            this.loadPostCatalog(data.id, resolve)
          } else {
            resolve(data.children)
          }
        } else if (data.archiveType == this.archiveType.catalog) {
          // 加载卷内目录
          this.loadPostDir(data.id, resolve)
        }
      }
    },

    // 加载案卷目录列表
    loadPostCatalog(outlineId, resolve) {
      this.$http.get(
          '/mps/archivePostCatalog/list',
          {
            params: {
              outlineId: outlineId
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          res.data.forEach(item => {
            // 案卷目录数据 拼接树结构
            item.archiveType = this.archiveType.catalog // 当前节点类型
            item.outlineLeaf = false // 是否是分类大纲的叶子节点
            item.hasChildren = item.hasDir // 是否有子节点
          })
        }
        resolve(res.data)
      })
    },
    // 加载卷内目录列表
    loadPostDir(catalogId, resolve) {
      this.$http.get(
          '/mps/archivePostDir/list',
          {
            params: {
              catalogId: catalogId
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data != null && res.data.length > 0) {
          res.data.forEach(item => {
            // 案卷目录数据 拼接树结构
            item.archiveType = this.archiveType.dir // 当前节点类型
            item.outlineLeaf = false // 是否是分类大纲的叶子节点
            item.hasChildren = false // 是否有子节点
          })
        }
        resolve(res.data)
      })
    },
    hasChildren(data) {
      return !data.hasChildren
    },
    // 节点是否允许拖动
    preAllowDrag(node) {
      let data = node.data
      // 目录、文件都允许拖动
      return data && (data.archiveType == this.archiveType.catalog)
    },
    preAllowDrop() {
      return false
    },

    // 组卷节点能否被放置
    postAllowDrop(draggingNode, dropNode, type) {
      let data = dropNode.data
      // 暂定 预组卷节点只能放置在组卷节点的案卷节点内
      return data.archiveType == this.archiveType.catalog && type == 'inner'
    },

    // 左侧预组件节点触发拖拽
    handleDragstart(node, event) {
      // 在左侧节点触发拖拽的时候触发右侧节点的拖拽事件
      this.$refs.treePost.$emit('tree-node-drag-start', event, {node: node})
    },

    // 左侧预组卷拖拽结束事件
    preNodeDragEnd(draggingNode, endNode, position, event) {
      // 新的空节点
      let newData = {id: (+new Date), name: (+new Date), children: []}
      // 左侧预组卷插入新的的空节点
      this.$refs.treePre.insertBefore(newData, draggingNode)
      //右侧组卷触发结束拖拽事件
      this.$refs.treePost.$emit('tree-node-drag-end', event)
      this.$nextTick(() => {
        // 如果是移动到了自身
        if (this.$refs.treePre.getNode(draggingNode.data)) {
          this.$refs.treePre.remove(newData)
        } else {
          // 如果移动到右侧的组卷节点上
          let data = JSON.parse(JSON.stringify(draggingNode.data))
          this.$refs.treePre.insertAfter(data, this.$refs.treePre.getNode(newData))
          this.$refs.treePre.remove(newData)
        }
      })
    },

    // 组卷拖拽成功事件
    postNodeDrop(draggingNode, dropNode) {
      let preData = draggingNode.data
      // 删除预组卷拖过来的节点
      this.$refs.treePost.remove(preData)
      this.$confirm(`确定关联【${dropNode.data.name}】案卷吗?`, this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        let url = ''
        // 组卷节点的案卷目录Id
        let postCatalogId = dropNode.data.id
        let isOk = true
        if (preData.archiveType == this.archiveType.catalog) {
          url = `/mps/archiveQcFiles/copyCatalog/${preData.id}/${postCatalogId}`
          const loading = this.$loading({
            lock: true,
            text: '正在执行...',
            spinner: 'el-icon-loading',
            customClass: 'my-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$http.post(url).then(({data: res}) => {
            if (res.code !== 0) {
              isOk = false
              this.$alert(res.msg, '拷贝失败', {
                confirmButtonText: '关闭',
              })
            }
            this.$alert('关联成功！', '操作结果', {
              confirmButtonText: '关闭'
            })
          }).catch(() => {
            isOk = false
            this.$alert('关联失败', '操作结果', {
              confirmButtonText: '关闭'
            })
          }).finally(() => {
            loading.close()
            // 重新加载组卷的案卷
            if (isOk) {
              // 新的空节点
              let newData = {id: (+new Date), name: (+new Date), children: []}
              this.$refs.treePost.insertBefore(newData, dropNode)
              let data = JSON.parse(JSON.stringify(dropNode.data))
              if (!data.hasChildren) {
                data.hasChildren = true
              }
              this.$refs.treePost.remove(dropNode)
              this.$refs.treePost.insertAfter(data, this.$refs.treePost.getNode(newData))
              this.$refs.treePost.remove(newData)
              this.$refs.treePost.setCurrentKey(data.id)
              if (data.hasChildren == true) { // 重新展开该节点
                this.postExpandedKeys = [data.id]
              }
            }
          })
        }
      })
    },

    // 组卷节点能否拖动
    postAllowDrag(node) {
      // 判断该节点是否是组卷上的节点，不是则返回true、是则返回false
      return !this.$refs.treePost.getNode(node.data)
    }
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 140px);
}
</style>
